import { ID } from './ID'
export const enAccount = {
  // common
  [`${ID}heading`]: 'Profile settings',
  [`${ID}affiliationSub`]: 'This display name will only appear within this community.',
  [`${ID}limit`]: 'If you set "Limited Release", it will be open only to the participants of the group to which you belong.',
  [`${ID}checkPortfolio`]: 'Check your portfolio',
  [`${ID}portfolioSetting`]: 'Portfolio setting',
  [`${ID}viewProfile`]: 'View my profile',
  [`${ID}currentJob`]: 'Current position information',
  [`${ID}updateYourProfile`]: 'Update your profile',
  [`${ID}updateFinished`]: 'It has been updated.',
  [`${ID}top`]: 'TOP',
  [`${ID}emailNotice`]: 'A notification email will be sent to your account email address during X / Y / Z.',
  [`${ID}receiveMail`]: 'Receive',
  [`${ID}notReceiveMail`]: 'Not receive',
  [`${ID}memoUpdate`]: 'Please note that after changing the settings, the settings information will not be updated unless you click the update button.',
  [`${ID}settingComment`]: 'Comment',
  [`${ID}settingMention`]: 'Mention',
  [`${ID}settingChatting`]: 'Chat',
  [`${ID}settingInterested`]: 'Interested',
  [`${ID}emailNoticeRegis`]: 'An email notification will be sent to the registered email address when the checked action occurs.',
  SG41communityProfileNotice: 'Auto-post profile updates',
  SG41communityPortfoliosNotice: 'Auto-post portfolio updates',
  SG41communityEmailNotice: 'Email notification reception setting',
  SG40updateError: 'Profile update failed',
  SG40infoImage: 'Recommended size is 180px in height and 180px in width',
  SG40show: 'Post',
  SG40notShow: 'Do not post',
  emailNotificationInstant: 'Instant email notification settings',
  emailNotificationUnread: 'Unread email notification settings',
}
