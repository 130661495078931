import { COMMUNITY_SITE_ADMIN_STORE, POST_STORE, USER_POST_STORE } from '@/store/constants'
import { CommunitySiteArticle } from '@/store/modules-community-admin/community-site/index'
import userPostService, { UpdateStatusMessage } from '@/services/user-post.services'
import postService from '@/services/post.services'
import communitySiteAdminServices from '@/services/community-site-admin.services'
import { PUBLISH_STATUS_NONE } from '@/views/community-admin/community-site/constants'
import { ERROR_STATUS_HAS_CHANGED } from '@/constants'
import router from '@/router'
type CommitType = {
  commit: any
}

export const actions = {
  async [COMMUNITY_SITE_ADMIN_STORE.ACTIONS.SET_ARTICLE_DATA]({ commit }: CommitType, payload: { data: CommunitySiteArticle; imageMapping: any }): Promise<void> {
    commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.SET_ARTICLE, payload.data)
    commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.SET_IMAGE_MAPPING, payload.imageMapping)
  },
  async [COMMUNITY_SITE_ADMIN_STORE.ACTIONS.CLEAR_FORM_ARTICLE_DATA]({ commit }: CommitType): Promise<void> {
    commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.CLEAR_ARTICLE_DATA)
  },
  async [COMMUNITY_SITE_ADMIN_STORE.ACTIONS.UPDATE_RESET_FLAG]({ commit }: CommitType, payload: { flag: boolean }): Promise<void> {
    commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.UPDATE_RESET_FLAG, payload)
  },
  async [COMMUNITY_SITE_ADMIN_STORE.ACTIONS.GET_ARTICLE_DETAIL](
    { commit }: CommitType,
    { screen, community_id, postId, params }: { screen: string; community_id: string; postId: string; params: any }
  ): Promise<void> {
    try {
      const paramsRequest = { target_content_id: params?.target_content_id, from_ct_10: params?.from_ct_10 }
      const data: any = await userPostService.detailUserPosts(community_id, postId, paramsRequest)
      if (data.data?.success) {
        const { post = {} } = data?.data?.data
        if (params?.destination === 'list') {
          commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.UPDATE_LIST, post)
        } else {
          commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.GET_ARTICLE_DETAIL, post)
        }
        commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.SET_COMMENT, {
          comments: post.comments ?? [],
          total: post.comment_count ?? 0,
        })
      } else if (data?.response?.status === 404) {
        router.push('/404')
      } else {
        commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.GET_ARTICLE_DETAIL, 'error')
      }
    } catch (error) {
      console.log(error)
    }
  },
  async [COMMUNITY_SITE_ADMIN_STORE.ACTIONS.GET_COMMENT_LIST]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data = await postService.listComment(payload?.community_id, payload?.params)

      if (data.data?.success) {
        commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.SET_COMMENT, {
          comments: data.data.data ?? [],
          total: data.data.data.length ?? 0,
        })
      }
    } catch (error) {
      console.log(error)
    }
  },
  async [COMMUNITY_SITE_ADMIN_STORE.ACTIONS.GET_ARTICLE_LIST]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const { query: params, id } = payload
      if (params && params.published === PUBLISH_STATUS_NONE) {
        commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.SET_ARTICLE_LIST, {
          records: [],
          totalPages: 0,
          totalRecords: 0,
        })
        return
      }
      const data = await communitySiteAdminServices.getArticleList(id, params)
      if (data.data?.success && data.data.data.status === 200) {
        const { records, total_pages: totalPages, total_records: totalRecords } = data.data.data
        commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.SET_ARTICLE_LIST, {
          records,
          totalPages,
          totalRecords,
        })
      }
    } catch (error) {
      console.log(error)
    }
  },
  async [COMMUNITY_SITE_ADMIN_STORE.ACTIONS.UPDATE_COMMENT]({ commit }: CommitType, payload: UpdateStatusMessage): Promise<any> {
    try {
      const data: any = await userPostService.updateStatusMessage(payload)

      if (data.data?.success) {
        commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.UPDATE_COMMENT, data?.data?.data)
        return {
          success: true,
        }
      } else if (data?.response?.data?.data[0]?.message_code === ERROR_STATUS_HAS_CHANGED) {
        commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.DELETE_COMMENT, payload)
        return {
          success: false,
          message_code: ERROR_STATUS_HAS_CHANGED,
        }
      }
      return {
        success: false,
        message_code: data?.response?.data?.data[0]?.message_code,
      }
    } catch (error) {
      return {
        success: false,
        message_code: null,
      }
    }
  },
  async [COMMUNITY_SITE_ADMIN_STORE.ACTIONS.CLEAR_SELECTED_DATA]({ commit }: CommitType, payload: UpdateStatusMessage): Promise<any> {
    commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.CLEAR_SELECTED_DATA, payload)
  },
  async [COMMUNITY_SITE_ADMIN_STORE.ACTIONS.GET_USER_MENTION_LIST]({ commit }: CommitType, { id, create_by_admin }: any): Promise<any> {
    try {
      const res = await postService.getUserMentionPost(id, { create_by_admin })

      if (res.data?.success) {
        commit(POST_STORE.MUTATIONS.SET_USER_MENTION_POST, res.data)
      }
    } catch (error) {
      console.log(error)
    }
  },
  async [COMMUNITY_SITE_ADMIN_STORE.ACTIONS.STORE_CURRENT_QUERY]({ commit }: CommitType, payload: any): Promise<any> {
    commit(COMMUNITY_SITE_ADMIN_STORE.MUTATIONS.SET_CURRENT_QUERY, payload)
  },
}
