import { COMMUNITY_SITE_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { Module } from 'vuex'
import { mutations } from '@/store/modules/community-site/community_site.mutations'
import { actions } from '@/store/modules/community-site/community_site.actions'
import { getters } from '@/store/modules/community-site/community_site.getters'
import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'

// Community Site Post = CSP

const state: CSPStateTypes = {
  posts: [],
  total_pages: 0,
  loadingPosts: false,
  post: null,
  total_records: 0,
  userMentionPost: [],
}

const root: Module<CSPStateTypes, RootState> = {
  state,
  mutations,
  actions,
  getters,
}

export default root

export type CSPStateTypes = {
  posts: Array<CSPType>
  total_pages: number
  loadingPosts: boolean
  post: CSPType | null
  total_records: number
  userMentionPost: Array<any>
}

export type CSPGettersTypes = {
  [COMMUNITY_SITE_STORE.GETTERS.COMMUNITY_SITE_POSTS](state: CSPStateTypes): CSPStateTypes
}
export interface CSPActionTypes {
  [COMMUNITY_SITE_STORE.ACTIONS.GET_COMMUNITY_SITE_POSTS]({ commit }: any, payload: any): void
}

export type CSPMutationTypes<S = CSPStateTypes> = {
  [COMMUNITY_SITE_STORE.MUTATIONS.SET_COMMUNITY_SITE_POSTS](state: S, payload: any): void
}

export type CommunitySiteModuleType<S = CSPStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof CSPMutationTypes, P extends Parameters<CSPMutationTypes[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<CSPMutationTypes[K]>
} & {
  getters: {
    [K in keyof CSPGettersTypes]: ReturnType<CSPGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof CSPActionTypes>(key: K, payload?: Parameters<CSPActionTypes[K]>[1], options?: DispatchOptions): ReturnType<CSPActionTypes[K]>
}

export type UserType = 'employee' | 'alumni'

export type CSPType = {
  comments: Array<any>
  community_id: number
  community_management_id: number
  content: string
  content_type: number
  created_at: string
  updated_at: string
  display_name: string
  favorite: boolean
  image_url: string
  interest: boolean
  like: boolean
  tags: Array<any>
  title: string
  user_type: UserType
  id: string
  comment_count: number
  like_count: number
}
