export const jpEditCommunity = {
  CO40title: 'コミュニティ編集',
  CO40buttonTop: 'トップへ',
  CO40messageError: 'コミュニティ更新に失敗しました。',
  CO41title: 'コミュニティ編集確認',
  CO40notice: '※非公開にすると管理ユーザ以外はコミュニティに入室できなくなります。',
  CO40noticeImage: '※未設定の場合は、ヘッダーロゴが表示されます。',
  CO40text1: 'コミュニティの運営元やメッセージの送信元として公開される法人・組織名を入力ください。',
  CO40text2: '※アップロード可能なファイル形式：.jpg／.jpeg／.png',
  CO40text3: '※縦180px以上、横180px以上を推奨',
  CO40text31: '※縦280px以上、横280px以上を推奨',
  CO40text4: '※ファイル容量上限：20MB',
  CO40text5: '※画像サイズは自動調整され正方形で表示されます。',
  CO40text51: '※画像サイズは自動調整され円で表示されます。',
  CO40interestUserDisplaySetting: '「興味あり」ユーザ表示設定',
  CO40interestUserDisplaySettingNote: '※チェックをOFFにすると、個人ユーザは投稿者以外、誰が興味ありを押したか閲覧できなくなります。',
  CO40settingComment: 'コメント',
  CO40settingMention: 'メンション',
  CO40settingChatting: 'チャット',
  CO40settingInterested: '興味あり',
  CO40emailNoticeRegis: '※チェックを入れたアクションが行われた際に、管理ユーザ宛にメール通知が送られます。',
  CO40emailNotificationInstant: '即時メール通知',
}
