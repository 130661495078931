import { COMMUNITY_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { GetterTree } from 'vuex'
import { CommunityGettersTypes, CommunityStateTypes } from '.'
import { pick } from 'lodash'

export const getters: GetterTree<CommunityStateTypes, RootState> & CommunityGettersTypes = {
  [COMMUNITY_STORE.GETTERS.COMMUNITY_INFO]: (state: CommunityStateTypes): CommunityStateTypes => state,
  [COMMUNITY_STORE.GETTERS.GET_ME]: (state: CommunityStateTypes) => state.me,
  // 2022/10/05 TuanTT Add community pagiantion UAT#4485: SD10,CB11(Ph2-R20221014)
  [COMMUNITY_STORE.GETTERS.COMMUNITY]: (state: CommunityStateTypes) => state.community,
  [COMMUNITY_STORE.GETTERS.GET_LOADING]: (state: CommunityStateTypes) => state.loading,
  [COMMUNITY_STORE.GETTERS.GET_INTEREST_LIST_VISIBLE_OPTION]: (state: CommunityStateTypes) =>
    pick(state, 'interest_display_setting_joboffer', 'interest_display_setting_recruitment'),
}
