import LayoutAdmin from '@/components/layouts/layout-admin/index.vue'
import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/community/communities/:id/setting_community_site',
      name: 'community site settings',
      component: () => import('@/views/community-admin/community-management/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        layout: LayoutAdmin,
        screenId: 'CL10',
      },
    },
  ]
}
