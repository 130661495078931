export const enCommunitySite = {
  CK10ArticleList: 'Article list',
  CK10PublicStatus: 'Public',
  CK10PrivateStatus: 'Private',
  CK10WriteAnArticle: 'Write an article',
  CK10PublishStatus: 'Status',
  CK10NoSearchData: 'No community site articles found.',
  CK16DeleteArticle: 'Delete an article',
  CK16DeleteArticleConfirmMessage: "Delete article '%s'.",
  CK20ArticleCreation: 'Community site new registration',
  CM10ArticleUpdate: 'Community site update',
  CM20ArticleUpdateConfirm: 'Community site article editing confirmation',
  CK20ContentTextLimit: 'Character limit: %s characters',
  CK20ContentImageLimit: 'Image limit: %s images',
  CK20ContentImageSizeLimit: 'Image size limit: %sMB',
  CK20ContentImageFormat: 'Allowed image file formats for upload: .jpg / .jpeg / .png',
  CK20CommunitySiteTag: 'コミュニティサイト記事',
  CK20CurrentNumberOfCharacters: 'Current number of characters: %d',
  CK20AutoPost: 'Automatic Posting',
  CK20AutoPostYes: 'Yes',
  CK20AutoPostDescription: 'Automatic posting is a function that automatically posts the "first image" and "opening 300 characters of text" of the article to the timeline.',
  CK20AutoPostNo: 'No',
  CK30CreateArticleConfirmTitle: 'Community site new registration confirmation',
  CK30CreateArticleSuccessTitle: 'Create a new community site article',
  CK30CreateArticleSuccessDescription: 'Registered.\n' + 'Virus checks for attachments such as images may take time.',
  CM20CreateArticleSuccessDescription: 'Updated.\n' + 'Virus checks for attachments such as images may take time.',
  CK30CreateArticleFailedToastMsg: 'Failed to register community site article.',
  CK20TagDescription: 'Once you confirm entering the text, it will be added as a tag.\nYou can set up to five tags per post.',
  CK12TotalNumberOfComment: 'See %s other comments',
  CK20TooltipBackground: 'Background Color',
  CK20TooltipBold: 'Bold',
  CK20TooltipColor: 'Text Color',
  CK20TooltipFont: 'Font',
  CK20TooltipInlineCode: 'Inline Code',
  CK20TooltipItalic: 'Italic',
  CK20TooltipLink: 'Insert Link',
  CK20TooltipSize: 'Font Size',
  CK20TooltipStrike: 'Strikethrough',
  CK20TooltipUnderline: 'Underline',
  CK20TooltipBlockQuote: 'Quote',
  CK20TooltipHeader1: 'Heading 1',
  CK20TooltipHeader2: 'Heading 2',
  CK20TooltipIndentPos1: 'Increase Indent',
  CK20TooltipIndentNeg1: 'Decrease Indent',
  CK20TooltipListOrdered: 'Numbered List',
  CK20TooltipListBullet: 'Bulleted List',
  CK20TooltipListTextDirection: 'Text Direction',
  CK20TooltipListTextAlignment: 'Alignment and Indent',
  CK20TooltipListTextAlignmentLeft: 'Left',
  CK20TooltipListTextAlignmentRight: 'Right',
  CK20TooltipListTextAlignmentCenter: 'Center',
  CK20TooltipListTextAlignmentJustify: 'Justify',
  CK20TooltipCodeBlock: 'Code Block',
  CK20TooltipImage: 'Insert Image',
  CK20TooltipClean: 'Clear Formatting',
  CK10PublishedDate: 'First published date',
}
