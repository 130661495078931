import { mutations } from './register.mutations'
import { getters } from './register.getters'
import { actions } from './register.actions'

import { Module } from 'vuex'
import { RegisterState } from '@/store/store.type'
import { RegisterStateTypes } from './register.types'

const today = new Date()
const currentMonth = today.setDate(1)
const priorDate = new Date('January 1').setFullYear(today.getFullYear() - 30)

export const state: RegisterStateTypes = {
  invite: {},
  community_required_columns: {},

  registerAlumnusData: {
    invited_code: '',
    family_name: '',
    given_name: '',
    family_name_kana: '',
    given_name_kana: '',
    display_name: '',
    birthday: priorDate,
    email: '',
    institution: '',
    zipcode_1: '',
    zipcode_2: '',
    zipcode_foreign: '',
    zipcode_judgment: false,
    address_1: '',
    address_2: '',
    phone_number_1: '',
    phone_number_2: '',
    phone_number_3: '',
    job_type_parent_id: '',
    job_type_child_id: '',
    enrollment_start_period: currentMonth,
    password: '',
    confirmPassword: '',
    secondary_password: '',
    corporation_organization_name: '',
    corporation_department_name: '',
    corporation_start_period: currentMonth,
    corporation_end_period: currentMonth,
    corporation_employee_id: '',
    corporation_email: '',
    corporation_family_name: '',
    corporation_given_name: '',
    consent_of_yelloop: false,
    consent_of_community: false,
    version_of_yelloop: 1,
    version_of_community: 2,
    school_organization_name: '',
    school_faclty_name: '',
    school_department_name: '',
    school_start_period: currentMonth,
    school_end_period: currentMonth,
    school_student_id: '',
    school_email: '',

    school_family_name: '',
    school_given_name: '',
    additional_information_1_question: '',
    additional_information_1: '',
    additional_information_2_question: '',
    additional_information_2: '',
    additional_information_3_question: '',
    additional_information_3: '',
    additional_information_4_question: '',
    additional_information_4: '',
    additional_information_5_question: '',
    additional_information_1_setting: { data: [], type: 0 },
    additional_information_2_setting: { data: [], type: 0 },
    additional_information_3_setting: { data: [], type: 0 },
    additional_information_4_setting: { data: [], type: 0 },
    additional_information_5_setting: { data: [], type: 0 },
    additional_information_5: '',
  },
  registerAlumnusCommunityData: {
    invited_code: '',
    display_name: '',
    corporation_department_name: '',
    corporation_start_period: '',
    corporation_end_period: '',
    corporation_employee_id: '',
    corporation_email: '',
    corporation_family_name: '',
    corporation_given_name: '',
    school_faclty_name: '',
    school_department_name: '',
    school_start_period: '',
    school_end_period: '',
    school_student_id: '',
    school_email: '',

    school_family_name: '',
    school_given_name: '',
    additional_information_1_question: '',
    additional_information_1: '',
    additional_information_2_question: '',
    additional_information_2: '',
    additional_information_3_question: '',
    additional_information_3: '',
    additional_information_4_question: '',
    additional_information_4: '',
    additional_information_5_question: '',
    additional_information_5: '',
    additional_information_1_setting: { data: [], type: 0 },
    additional_information_2_setting: { data: [], type: 0 },
    additional_information_3_setting: { data: [], type: 0 },
    additional_information_4_setting: { data: [], type: 0 },
    additional_information_5_setting: { data: [], type: 0 },
    consent_of_community: false,
    version_of_community: 2,
  },
  termsYelloop: {},
  termsCommunity: {},
  listJobs: {},
  infoAlumnus: {},
  alumnusDuplicate: '',
  validatePostAlum: '',
  validatePostAlumCommunity: '',
}

const register: Module<RegisterStateTypes, RegisterState> = {
  state,
  mutations,
  actions,
  getters,
}

export default register
