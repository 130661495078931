import { mutations } from './groups.mutations'
import { getters } from './groups.getters'
import { actions } from './groups.actions'

import { Module } from 'vuex'
import { GROUPS_STORE } from '@/store/constants'
import { Store as VuexStore, CommitOptions, DispatchOptions } from 'vuex'

export const state: GroupsStateTypes = {
  listGroups: [],
  allGroups: [],
  listGroupsLoadMore: [],
  loading: false,

  listUserGroup: [],
  detailGroup: {},
  listTalkRooms: [],
  listTalkRoomsLoadMore: [],
  detailTalkRoom: {},
  total_pages: 0,
  loadingGroups: false,
  total_pages_talk: 0,
  loadingTalkRooms: false,
  loadingDetail: false,
  preventLoadingAgain: false,
  listRecordSelected: [],
  mentionUsers: [],
}

const groups: any = {
  state,
  mutations,
  actions,
  getters,
}

export default groups

export type GroupsStateTypes = {
  listGroups: any
  allGroups: any[]
  listGroupsLoadMore: any
  listTalkRoomsLoadMore: any
  loading: boolean

  listUserGroup: any
  detailGroup: any
  listTalkRooms: any
  detailTalkRoom: any
  total_pages: number
  total_pages_talk: number
  loadingGroups: boolean
  loadingTalkRooms: boolean
  loadingDetail: boolean
  preventLoadingAgain: boolean
  listRecordSelected: any
  mentionUsers: any
}

export interface GroupsGettersTypes {
  [GROUPS_STORE.GETTERS.LIST_GROUPS](state: GroupsStateTypes): any
  [GROUPS_STORE.GETTERS.ALL_GROUPS](state: GroupsStateTypes): any
  [GROUPS_STORE.GETTERS.LIST_GROUPS_LOAD_MORE](state: GroupsStateTypes): any
  [GROUPS_STORE.GETTERS.LIST_USER_GROUP](state: GroupsStateTypes): any
  [GROUPS_STORE.GETTERS.DETAIL_GROUPS](state: GroupsStateTypes): any
  [GROUPS_STORE.GETTERS.LIST_TALK_ROOMS](state: GroupsStateTypes): any
  [GROUPS_STORE.GETTERS.DETAIL_TALK_ROOM](state: GroupsStateTypes): any
  [GROUPS_STORE.GETTERS.LIST_MENTION_USER](state: GroupsStateTypes): any
}

export type GroupsMutationTypes<S = GroupsStateTypes> = {
  [GROUPS_STORE.MUTATIONS.SET_LIST_GROUPS](state: S, payload: any): void
  [GROUPS_STORE.MUTATIONS.SET_ALL_GROUPS](state: S, payload: any): void
  [GROUPS_STORE.MUTATIONS.SET_LIST_USER_GROUP](state: S, payload: any): void
  [GROUPS_STORE.MUTATIONS.CREATE_GROUPS](state: S, payload: any): void
  [GROUPS_STORE.MUTATIONS.EDIT_GROUPS](state: S, payload: any): void
  [GROUPS_STORE.MUTATIONS.DELETE_GROUPS](state: S, payload: any): void
  [GROUPS_STORE.MUTATIONS.SET_DETAIL_GROUPS](state: S, payload: any): void
  [GROUPS_STORE.MUTATIONS.SET_LIST_TALK_ROOMS](state: S, payload: any): void
  [GROUPS_STORE.MUTATIONS.SET_DETAIL_TALK_ROOM](state: S, payload: any): void
  [GROUPS_STORE.MUTATIONS.SET_LIST_MENTION_USER](state: S, payload: any): void
}

export interface GroupsActionTypes {
  [GROUPS_STORE.ACTIONS.SET_LIST_GROUPS]({ commit }: any, payload: any): void
  [GROUPS_STORE.ACTIONS.SET_ALL_GROUPS]({ commit }: any, payload: any): void
  [GROUPS_STORE.ACTIONS.SET_LIST_USER_GROUP]({ commit }: any, payload: any): void
  [GROUPS_STORE.ACTIONS.CREATE_GROUPS]({ commit }: any, payload: any): void
  [GROUPS_STORE.ACTIONS.EDIT_GROUPS]({ commit }: any, payload: any): void
  [GROUPS_STORE.ACTIONS.DELETE_GROUPS]({ commit }: any, payload: any): void
  [GROUPS_STORE.ACTIONS.SET_DETAIL_GROUPS]({ commit }: any, payload: any): void
  [GROUPS_STORE.ACTIONS.SET_LIST_TALK_ROOMS]({ commit }: any, payload: any): void
  [GROUPS_STORE.ACTIONS.SET_DETAIL_TALK_ROOM]({ commit }: any, payload: any): void
  [GROUPS_STORE.ACTIONS.SET_LIST_MENTION_USER]({ commit }: any, payload: any): void
}

export type GroupsModuleType<S = GroupsStateTypes> = Omit<VuexStore<S>, 'commit' | 'getters' | 'dispatch'> & {
  commit<K extends keyof GroupsMutationTypes, P extends Parameters<GroupsMutationTypes[K]>[1]>(key: K, payload?: P, options?: CommitOptions): ReturnType<GroupsMutationTypes[K]>
} & {
  getters: {
    [K in keyof GroupsGettersTypes]: ReturnType<GroupsGettersTypes[K]>
  }
} & {
  dispatch<K extends keyof GroupsActionTypes>(key: K, payload?: Parameters<GroupsActionTypes[K]>[1], options?: DispatchOptions): ReturnType<GroupsActionTypes[K]>
}
