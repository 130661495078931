export const jpLogin = {
  SA80title: 'Join "YELLoop"',
  SA80alumnus: 'つながる人たちにエールを。',
  SA80alumnus1: 'つながる人たちにエールを。',
  SA80li: 'ダミーテキストダミーテキスト',
  SA80remember: 'ログイン状態を保存',
  SA80errorGGreCaptcha: 'キャプチャをチェックしてください。',
  SA80contactFirst: 'ログインができずお困りの場合は',
  SA80contactSecond: 'お問い合わせ',
  SA80contactThird: 'ください。',
  CA80alumnus: 'YELLoop（エーループ）は、アルムナイとの新しい関係づくりを支援する、コミュニティプラットフォームサービスです。',
  CA80title: 'Join "YELLoop"',
  SA80alumnus2: 'YELLoop（エーループ）は、所属していた組織との新しい関係づくりを支援する、コミュニティプラットフォームサービスです。',
  SA80snsIntegrationNote1: 'ソーシャルログイン連携は、',
  SA80snsIntegrationNote2: 'ログイン後の「アカウント設定」よりご設定ください。',
}
