import { RouteRecordRaw } from 'vue-router'
import LayoutDefault from '@/components/layouts/layout-default/index.vue'
import LayoutEmployee from '@/components/layouts/layout-employee/index.vue'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/alumni/communities/:id/post/:post_id',
      name: 'alumni post detail',
      component: () => import('./index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SR40',
        layout: LayoutDefault,
      },
    },
    {
      path: '/community/communities/:id/post/:post_id',
      name: 'community post detail',
      component: () => import('./index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SR40',
        layout: LayoutEmployee,
      },
    },
  ]
}
