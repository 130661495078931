import { jpCompleteRegistration } from '@/views/user/alumni/complete-registration/languages/jp'

import { jpAlumniRegistration } from '@/views/user/alumni/alumni-registration/language/jp'
import { jpForgotPassword } from '@/views/user/authentication/forgot-password/languages/jp'
import { jpResetPassword } from '@/views/user/authentication/reset-password/languages/jp'
import { jpSentMail } from '@/views/user/authentication/send-email-recovery-password-success/languages/jp'
import { jpRegister } from '@/views/user/checking/invitation/language/jp'
import { jpRegisterAlumnusCommunity } from '@/views/user/register-alumnus-communities/register-alumnus-community/language/jp'

import { jpRegisterCommunityFinished } from '@/views/user/register-alumnus-communities/register-alumnus-community-finished/language/jp'
import { jpLogin } from '@/views/user/authentication/login/language/jp'
import { jpAccount } from '@/views/user/account/profile/setting/languages/jp'
import { jpJoinUsers } from '@/views/user/users/join/join-users/language/jp'
import { jpJoinUsersFinished } from '@/views/user/users/join/join-users-finished/language/jp'
import { jpLobby } from '@/views/user/community/lobby/language/jp'
import { jpAccountEdit } from '@/views/user/account/profile/edit/language/jp'
import { jpLeaveCommunity } from '@/views/user/checking/leave/language/jp'
import { jpCancelYelloop } from '@/views/user/checking/cancel-yelloop/language/jp'
import { jpInquiry } from '@/views/user/users/inquiry/language/jp'
import { jpCommunityForgotEmail } from '@/views/community-admin/authentication/forgot-email/languages/jp'
import { jpTermOfUses } from '@/views/community-admin/term-of-uses/term-of-uses-edit/languages/jp'
import { jpNotificationCommunity } from '@/views/community-admin/notification-community/languages/jp'
import { jpUserManagementFlag } from '@/views/community-admin/user-management/user-management-flag/language/jp'
import { jpListUserCommunity } from '@/views/community-admin/user-management/alumni-user/language/jp'
import { jpGeneralUserCommunity } from '@/views/community-admin/user-management/employee-user/language/jp'
import { jpAlumniProfile } from '@/views/user/alumni/alumni-profile/language/jp'
import { jpCommunityPortfolios } from '@/views/user/account/portfolio/language/jp'
import { jpGroups } from '@/views/user/groups/language/jp'
import { jpConversation } from '@/views/user/conversations/languages/jp'
import { jpEditCommunity } from '@/views/community-admin/edit-community/languages/jp'
import { jpUserManagement } from '@/views/community-admin/user-management/languages/jp'
import { jpAdminRegisterForm } from '@/views/community-admin/alumni/register-form/language/jp'
import { jpCommunityCandidates } from '@/views/community-admin/approval/languages/jp'
import { jpMessagesTemplate } from '@/views/community-admin/message-template/language/jp'
import { jpContractInformation } from '@/views/community-admin/user-management/contract-information/languages/js'
import { jpSubmitResetAdmin } from '@/views/community-admin/authentication/forgot-password/languages/jp'
import { jpTalkRoom } from '@/views/community-admin/group-list/languages/jp'
import { jpDashboard } from '@/views/community-admin/dashboard/language/jp'
import { jpCommunityManagement } from '@/views/community-admin/community-management/languages/jp'
import { jpPoints } from '@/views/yellloop-point/language/jp'
import { jpCommunitySite } from '@/views/community-admin/community-site/language/jp'

export const jp = {
  //common
  titleHeadCommunityAdmin: 'YELLoop 管理画面',
  titleHeadUser: 'YELLoop',
  top: 'トップ',
  home: 'ホーム',
  account: 'アカウント設定',
  point: 'YELLポイント',
  logout: 'ログアウト',
  password: 'パスワード',
  currentPassword: '現在のパスワード',
  confirmPassword: 'パスワードを認証する',
  confirmPasswordAlumni: 'パスワード（確認用）',
  email: 'メールアドレス',
  communityHome: 'コミュニティホーム',
  chat: 'チャット',
  group: 'グループ',
  message: 'メッセージ',
  almunai: 'アルムナイ',
  almunaiAddUser: 'アルムナイ招待',
  question: 'よくある質問',
  editPost: '投稿を編集',
  deletePost: '投稿を削除',
  deleteComment: 'コメントを削除',
  postEdit: '修正する',
  postSubmit: '投稿する',
  postSubmitEdit: '更新する',
  all: 'すべて',
  participatingGroups: '参加中のグループ',
  manageGroup: '作成したグループ',
  article: '記事',
  recruitment: '募集',
  jobOffer: '求人',
  keep: 'キープ',
  userManagement: 'ユーザ管理',
  enroll: '在籍',
  employeeUser: '在籍ユーザ',
  chatManagement: 'チャット管理',
  almunaiParManagement: 'アルムナイ参加管理',
  waitingApproval: '承認待ちアルムナイ',
  registrationForm: ' 登録フォーム',
  candidateList: 'アルムナイ候補者リスト',
  communitySiteManagement: 'コミュニティサイト管理',
  communitySitePosts: 'コミュニティサイト記事一覧',
  communitySiteManagementHeaderText: 'ヘッダーテキスト',
  CP40ContractTypeOption: 'オプション',
  postManagement: '投稿管理',
  userPost: 'ユーザ投稿',
  userPost2: 'ユーザ投稿一覧',
  adminUserPost: '管理ユーザ投稿一覧',
  listAdminUserPost: '運営事務局投稿一覧',
  groupManagement: 'グループ管理',
  messageManagement: 'メッセージ管理',
  list: '一覧',
  messageHistory: 'メッセージ送信履歴',
  messageTemplate: 'メッセージテンプレート',
  titleMessageTemplate: 'メッセージテンプレート削除',
  deleteButton: '削除する',
  deleteSuccess: '削除しました。',
  configuration: '設定',
  notice: 'お知らせ',
  community: 'コミュニティ',
  communityName: 'コミュニティ名',
  adminUser: '管理ユーザ',
  userFlag: 'ユーザ管理フラグ',
  termOfService: '利用規約',
  // message
  systemErr: `メールアドレスまたはパスワードが正しくありません。もう一度確認してからやり直してください。`,
  wrongPassword: '%sは%n文字以上、%n文字以下で入力してください。',
  requiredInput: '%sを入力してください。',
  requiredInputNoSubject: '入力してください。',
  requiredSelect: '%sを選択してください。',
  maxString: '%sは%n文字以下で入力してください。',
  minMaxString: '%sは%n文字以上、%n文字以下で入力してください。',
  minMaxStringNoSubject: '%n文字以上、%n文字以下で入力してください。',
  minNum: '%sは%n以上で入力してください。',
  maxNum: '%sは%n以内で入力してください。',
  validatePhone: '%sは電話番号の形式で入力してください。',
  validateMail: 'メールアドレスの形式で入力してください。',
  validatePictograms: '不正な文字が含まれています。',
  validateNumber: '半角数字で入力してください。',
  validateConfirmation: '%sと同じ値を入力してください。',
  errorLogin: 'ログインに失敗しました。',
  halfSizeNumber: 'half size number',
  alphabeticCharacters: 'alphabetic characters',
  fullSizeHiragana: '全角ひらがなを入力してください。',
  fullSizeKatakana: '全角カタカナで入力してください。',
  validateSymbol: 'validate symbol',
  validateEmoji: '不正な文字が含まれています。',
  validateNgWords: 'validate not good words',
  required: '入ってください',
  required1: '入力してください。',
  maxLengthMail: 'メールアドレスは%n文字以内で入力してください',
  forgotPassword: 'パスワードをお忘れの方',
  forgotEmail: 'メールアドレスをお忘れの方',
  canNotReceiveMail: 'メールが届かない方',
  titleButtonRegis: 'アカウント登録',
  titleButtonLogin: 'ログイン',
  name: '名',
  surName: '姓',
  invitationGroupUrl: 'グループ招待URL',
  submit: '次へ',
  confirm: '確認する',
  close: '閉じる',
  CloseButton: 'キャンセル',
  login: 'ログイン',
  confirmPasswordNotMatch: 'パスワードに入力いただいた値と一致しません。',
  passwordHaveText: 'パスワードにはテキストを含める必要があります',
  passwordHaveNumber: 'パスワードには番号を含める必要があります',
  signUp: '登録する',
  change: '変更する',
  changePasswordComplete: 'パスワードが正常に変更されました。',
  changePasswordComplete2: '新しいパスワードでログインしてください。',
  loginPasswordNew: '新しいパスワードでログインしてください。',
  loginBack: '新しいパスワードでログインしてください。',
  titleButtonConfirm: '確認する',
  titleButtonTop: 'トップ',
  agreeWithTerm: '利用規約に同意する',
  placeholder: '入力してください。',
  titleConfirm: '確認する',
  titleButtonEdit: '修正する',
  titleButtonRegisContent: 'この内容で登録する',
  titleRegis: '登録する',
  termOfServiceYell: 'YELLoop 会員規約',
  notification: 'お知らせ',
  popularTag: '人気のタグ',
  createPost: '投稿',
  userCreatePost: '投稿新規作成',
  content: '本文',
  title: 'タイトル',
  attachingFile: 'ファイル添付',
  attached: '添付あり',
  noAttachment: '添付なし',
  copyLink: 'リンクをコピー',
  userApplied: '応募したユーザ',
  writeComment: 'コメントを入力してください。',
  search: '検索',
  searchButton: '検索する',
  simpleSearch: '簡易検索',
  postArticle: '投稿する',
  postRecruitment: '募集を投稿する',
  post: '投稿する',
  passwordResetComplete: 'パスワードの再設定完了',
  resetPassword: 'パスワードの再設定',
  getInviteLink: 'こちらのリンクをシェアしてアルムナイを招待しよう。',
  like: 'いいね',
  interest: '興味あり',
  dateOfBirth: '生年月日',
  zipcode: '郵便番号',
  zipcode1: '郵便番号①',
  zipcode2: '郵便番号②',
  address: '住所',
  address1: '住所①',
  address2: '住所②',
  PhoneNumber: '電話番号',
  PhoneNumber1: '電話番号①',
  PhoneNumber2: '電話番号②',
  PhoneNumber3: '電話番号③',
  currentPosition: '現在お勤めの現職情報',
  affiliation: '所属',
  occupation: '職種',
  affiliationPeriod: '所属期間',
  accountSetting: 'アカウント設定',
  profile: 'プロフィール',
  profileImage: 'プロフィール画像',
  portfolio: 'ポートフォリオ',
  profileSetting: 'プロフィールとポートフォリオの公開設定',
  displayInProfile: 'プロフィールの更新を自動投稿',
  displayInPortfolio: 'ポートフォリオ更新時のタイムライン表示',
  emailNotification: 'メール通知受信設定',
  show: '表示する',
  notShow: '表示しない',
  private: '非公開',
  public: '公開',
  privateComment: 'コメントを非公開',
  publicAdmin: 'コメントを公開',
  limitPublic: '限定公開',
  appeal: 'アピール',
  leaveCommunity: 'コミュニティの脱退は',
  here: 'こちら',
  update: '更新する',
  back: '戻る',
  selectFile: 'ファイルを選択',
  notSelected: '選択されていません。',
  nameKanji: '氏名（漢字）',
  surNameKatakana: 'セイ',
  lastNameKatakana: 'メイ',
  placeholderSelect: '選択してください。',
  chatList: 'チャット一覧',
  administratorDirect: '管理者ダイレクト',
  chatPartner: 'チャットの相手',
  lastComment: '最後のコメント',
  watchChat: 'チャットを見る',
  viewDetail: '詳細を見る',
  send: '送信する',
  postTitle: 'タイトル',
  postContent: '本文',
  chatTitle: 'ユーザダイレクト',
  chatContent1: 'コミュニティ管理者にチャットができます。',
  chatContent2: 'お問い合わせ状況や質問により、回答に時間がかかる場合がありますので、あらかじめご了承ください。',
  chatContent3: '※対応可能時間はコミュニティにより異なります。詳細はコミュニティ管理者へお問い合わせください。',
  chatContent4: '※YELLoopの機能に関する質問やシステムのバグについては、別途お問い合わせフォームよりお問い合わせください。',
  chatContent5: 'コミュニティ管理者とのチャットです。',
  groupEditing: 'グループを編集',
  groupName: 'グループ名',
  groupDes: 'グループの説明',
  completionPage: '完了ページ',
  backToGroup: 'グループ一覧に戻る',
  displayContent: '※入力した内容を表示する',
  ques: 'この内容で登録してもよろしいでしょうか。',
  makeGroup: 'グループを作る',
  exit: '退出する',
  withdraw2: '脱退する',
  createGroup: 'グループの新規作成',
  createGroupAmin: 'グループ新規作成',
  inviteOnlyGroupSettings: '招待制設定',
  inviteOnlyGroup: '招待制',
  generalGroup: '通常',
  sendInviteOnlyGroupInfo: '招待制グループ',
  sendInviteOnlyGroupButton: '招待メッセージ一括送信',
  sendInviteOnlyInfo: '招待メッセージ',
  backToList: '一覧へ戻る',
  groupInvitationStatus: 'ステータス',
  NotInvitedToGroup: '未招待',
  invitedToGroup: '招待中',
  participatingInGroup: '参加中',
  groupInvitationDate: '参加日時',
  passwordSpecialWords: '使用できない文字列が含まれています。',
  // passwordHaveType: '%s要件は、上記の4つのタイプのうち3つを満たします：大文字、小文字、数字、特殊文字',
  passwordHaveType: 'パスワードは「半角英字(大文字)」「半角英字(小文字)」「半角数字」「半角記号」のうち、3種類以上を組み合わせて入力してください。',
  maxDate: '不正な日付が設定されました。',
  pastDate: '過去の日付は選択できません。',
  maxDateNyears: '%n年%n月〜今年から%n年後の年月の日付を選択してください。',
  afterStartDate: '終了日は開始日の後に入力してください。',
  afterStartDateEnrollment: '在籍期間：終了日は開始日の後に入力してください。',
  afterStartDatePeriodOfEnrollment: '在学期間：終了日は開始日の後に入力してください。',
  afterStartDateWork: '開始日より未来日を設定してください。',
  leaderShip: 'TOMODACHI Alumni Leadership Program の注意事項',
  agreeLeaderShip: 'の注意事項に同意する',
  agreeLeaderShipSB20: 'の注意事項',
  consent: '同意',
  agreeConfirm: 'コミュニティ注意事項に同意する',

  general: '在籍',
  work: 'テレワーク研究会',
  talkRoom: 'トークルーム',
  groupMember: 'グループメンバー',
  editGroup: 'グループを編集',
  deleteGroup: 'グループを削除',
  leaveGroup: 'グループを退出させる',
  deleteGroupUser: 'グループから削除',
  deleteGroupUserContent: 'をグループから削除します',
  mustAgree: '登録したい場合は、条件に同意する必要があります！',
  participant: '参加メンバー',
  editRoom: 'トークルームを編集',
  deleteRoom: 'トークルームを削除',
  talkRoomName: 'トークルーム名',
  talkRoomDes: 'トークルームの説明',
  communityMember: 'コミュニティ参加者',
  man: '人',
  nameKatakana: '氏名（カナ）',
  nickName: '表示名',
  add: '追加する',
  addAffiliation: '所属先を追加します。',
  companyName: '会社名・組織名',
  addWorkHistory: '職歴の追加',
  editWorkHistory: '職歴の編集',
  department: '部署',
  department_name: '所属',
  position: '役職',
  enrollment: '在籍期間',
  specificsContent: '具体的な内容',
  roleEtc: '役割や成し遂げたこと、達成したことなど',
  deleteJob: '職歴の削除',
  registerSuccess: '登録されました。',
  withdrawal: '脱退',
  cancel: 'キャンセル',
  toManagementScreen: '管理画面へ',
  delete: '削除',
  deleteHistoryMess: '取消',
  deletePostConfirm: '投稿を削除しますか？',
  deletePostSuccess: '投稿を削除されました。',
  addPersonalActivities: '個人的な活動の追加',
  editPersonalActivities: '個人的な活動を編集',
  removePersonalActivities: '個人的な活動を削除',
  deleteHistory: '履歴を削除します。',
  deleteHistorySuccess: '削除されました。',
  registerActivity: '「個人的な活動」を登録します。',
  activityTitle: '活動タイトル',
  activityPeriod: '活動期間',
  deleteStatement: '発言を削除します。',
  deleteGroupName: 'を削除します。',
  titleConfirmDeletePost: '投稿の削除',
  deleteGroupChat: 'グループを削除',
  leaveGroupConfirm: 'グループを退出させますか？',
  leaveGroupConfirm1: 'グループの退出',
  leaveInvitationGroupModalTitle: 'グループから退出',
  leaveInvitationGroupModalContent: 'から退出します',
  leaveInvitationGroupModalSuccess: '退出しました',
  exitGroup: '退出',
  chatContent: '発信',
  deleteChat: '発言を削除します。',
  deleteTalk: 'トークを削除します。',
  privateTalk: 'トーク非公開確認',
  privateTalkAdmin: 'トーク非公開の確認',
  publicTalk: '公開確認について話す',
  publicTalkAdmin: 'トーク公開',
  confirmPublicTalkAdmin: 'トーク公開の確認',
  privateChanged: 'トーク非公開完了',
  privateChangedAdmin: 'トーク非公開',
  publicChanged: '公開完了について話す',
  deleteChatSuccess: '発言を削除されました。',
  workExperience: '職歴',
  personalActivity: '個人的な活動',
  editWork: '職歴を編集',
  deleteWork: '職歴を削除',
  deletePersonalActivity: '個人的な活動の削除',
  withdraw: '退会する',
  hiddenPassword: 'セキュリティのため非表示にしています。',
  requiredDetails: '具体的な内容を入力してください',
  inquiryContentDetails: 'お問い合わせ内容を入力してください。',
  requiredEmailReply: '返信先メールアドレスを入力してください。',
  fullName: '氏名',
  basicInformation: '基本情報',
  emailConfirm: 'メールアドレス(確認用)',
  confirmEmailNotMatch: '入力されたメールアドレスが一致しません。',
  settings: '設定',
  adminUserSetting: '管理ユーザ設定',
  keyWord: 'キーワード',
  userSearch: 'ユーザ検索',
  createAdminUser: '管理ユーザを作成',
  managementNo: '管理No',
  customers: '顧客',
  lastLoginDate: '最終ログイン日時',
  deleteText: 'を削除します',
  deleted: '削除されました',
  adminRegistration: '管理ユーザ新規登録',
  adminRegistrationAdminUser: '管理ユーザを新規登録します。',
  adminRegistrationAdminUsesDes1: '入力したメールアドレス宛に認証メールをお送りします。',
  adminRegistrationAdminUsesDes2: '仮パスワードでログイン後、任意のパスワードを設定してください。',
  requireDepartment: '組織名や部署名などを入力してください。',
  registrationComplete: '登録しました。',
  registrationError: '登録失敗しました。',
  updateActivityComplete: '更新しました。',
  registrationFailed: '登録に失敗しました。',
  updateComplete: '更新しました。',
  updateCompleteFail: '更新失敗しました。',
  createTemplate: 'メッセージテンプレートを作成',
  templateName: 'テンプレート名',
  templateType: 'テンプレート種別',
  registrants: '登録者',
  registrationDate: '登録日時',
  edit: '編集',
  edit1: '編集する',
  createMessageTemplate: 'メッセージテンプレート新規登録',
  editMessageTemplate: 'メッセージテンプレート編集',
  createDes: 'メール本文中に ##__氏名__## という記述をすると、一括送信する 際に、実際の氏名が差し込まれます。',
  registered: '登録しました。',
  registeredFail: '登録失敗しました。',
  alumniInvitationUrl: 'アルムナイ招待URL',
  invitationUrl: '招待URL',
  forCompanies: '企業の場合',
  forSchool: '学校の場合',
  outsideJapan: '日本国外の場合',
  outsideJapanOption: '日本国外',
  additionalInformation: '追加情報',
  additionalInformation1Question: 'フリー項目①',
  additionalInformation2Question: 'フリー項目②',
  additionalInformation3Question: 'フリー項目③',
  additionalInformation4Question: 'フリー項目④',
  additionalInformation5Question: 'フリー項目⑤',
  additionalInformation1: '追加情報①',
  additionalInformation2: '追加情報②',
  additionalInformation3: '追加情報③',
  additionalInformation4: '追加情報④',
  additionalInformation5: '追加情報⑤',
  option: '選択肢',
  addOption: '＋選択肢を追加',
  additionalInformationFreeText: '自由記述',
  additionalInformationSingleSelect: '単一選択',
  additionalInformationMultipleSelect: '複数選択',
  formRegSetting: 'アルムナイ登録フォーム設定',
  formRegSetting1: 'アルムナイ登録フォームの設定画面です。',
  // formRegSetting2: 'その情報と予め登録している退職者情報を照らし合わせ、そのアルムナイが在籍していたかどうか自動的に在籍確認を行います。',
  formRegSetting3: 'アルムナイはコミュニティに招待された際に、共通項目を必須で入力します。',
  formRegSetting4: 'ここでは、在籍確認時に必要となる追加項目および追加情報を設定することができます。',
  formRegSetting5: '追加で取得したい情報があれば、フリー項目名をご入力ください。',
  formRegSetting6: '回答は自由記述形式になります。',
  formRegSetting7: '※運用途中でフリー項目名を変更する場合は、参加済ユーザの回答内容を考慮する必要がありますのでご注意ください。',
  supplementaryInformation: '補足説明',
  formRegSetting8: '追加した項目について、補足が必要であればご入力ください。',
  formRegSetting9: 'アルムナイには、追加項目回答欄の上部に表示されます。',
  alumniInvitationUrlIssue: '招待URL発行',
  urlissue1: 'アルムナイ招待URLを発行できます。',
  urlissue2: 'ラベルを変更することで登録経路を分けて管理することができます。',
  administrativeLabel: '管理ラベル',
  commonItem: '共通項目',
  commonItem1: '以下の項目は必須入力です。',
  commonItem2: '氏名（漢字）、氏名（カナ）、表示名、メールアドレス、生年月日、郵便番号、住所、電話番号、現職情報（所属、職種、所属期間）',
  participationItem: '参加項目',
  participationItem1: '追加したい項目があれば以下から選択してください。',
  participationItem2: '必須もしくは任意で回答してもらうことができます。',
  anyItem: '任意項目',
  updateQuestion: 'この内容で更新してもよろしいでしょうか。',
  regFormDes: '下記が、招待URLから登録するアルムナイ自身に入力してもらう項目となります。',
  publicOrganizationName: '公開組織名',
  managementOfficeName: '運営事務局名',
  explanatoryText: '説明文',
  messageBoard: 'メッセージボード',
  messageBoardTitle: '名称',
  messageBoardContent: '内容',
  messageBoardTitleNotEnteredCase: '※未入力の場合は「メッセージボード」と表示されます。',
  messageBoardSite1: 'サイト①',
  messageBoardSite2: 'サイト②',
  messageBoardSite3: 'サイト③',
  messageBoardSiteName1: 'サイト名①',
  messageBoardSiteName2: 'サイト名②',
  messageBoardSiteName3: 'サイト名③',
  messageBoardSiteUrl1: 'サイトURL①',
  messageBoardSiteUrl2: 'サイトURL②',
  messageBoardSiteUrl3: 'サイトURL③',
  invitationCodeNotCheckedCase: '※チェックをOFFにすると各ユーザは招待URLが発行できなくなります。',
  groupCreateNotCheckedCase: '※チェックをOFFにすると各ユーザはグループの新規作成ができなくなります。',
  pendingNoticeNotSelectedCase: '※新規の参加申請があった場合に管理ユーザ宛にメール通知が送られます。',
  unsupportedOperationsNoticeNotSelectedCase: '※未対応の内容があれば、管理ユーザ宛にメール通知が送られます。',
  notEnteredCase: '※未入力の場合は「運営事務局」と表示されます。',
  headerLogo: 'ヘッダーロゴ',
  managementOfficeProfileImage: '運営事務局プロフィール画像',
  setPrivateCase: '非公開に設定された場合は、「非公開にすると該当ユーザはコミニティに入室することができなくなります」という旨のメッセージを表示する。',
  publicSettings: '公開設定',
  invitationCodeSettings: '招待URL発行許可',
  groupCreateSettings: 'グループ新規作成許可',
  pendingNoticeSettings: '参加申請通知',
  unsupportedOperationsNoticeSettings: '未対応メール通知',
  receive: '受信する',
  notReceive: '受信しない',
  communityUpdate: 'コミュニティ更新',
  editCompleteTextModal: '編集完了しました。',
  approveList: '一括承認',
  deleteList: '一括削除',
  rejectList: '一括否決',
  selectItem: '件を選択中',
  selectAll: 'すべて選択',
  matchingNumber: '合致項目数',
  matchingDate: '突合日時',
  inviter: '招待者',
  deleteUserList: '選択されたユーザを一括削除します。',
  rejectUserList: '選択されたユーザを一括否決します。',
  approveUserList: '選択されたユーザを一括承認します。',
  selectingUserItems: '件のユーザを選択中',
  approved: '承認しました。',
  almunaiNode: 'アルムナイ',
  wattingAlmunaiUserDetail: '承認待ちアルムナイ詳細',
  kanjiName: '氏名(漢字)',
  katakanaName: '氏名(カナ)',
  currentPositionInfo: '現職情報',
  obtainedApplicationTimeInfo: '申請時取得情報',
  registrationRoute: '登録経路',
  applicationTime: '参加申請日時',
  collationConfirmInfo: '照合確認情報',
  totalNumberOfItems: '合数項目数',
  requiredItems: '必須項目',
  collationList: '照合リスト',
  enrolledDepartmentName: '在籍していた部署名',
  enrolledTimeStaffID: '在籍時の社員ID',
  enrolledTimeEmailID: '在籍時メールアドレス',
  examinationResult: '審査結果',
  approval: '承認',
  rejection: '否決',
  enrolledTimeOfNameKanji: '在籍時の氏名(漢字)',
  confirmExaminationResult: '審査結果の更新',
  updated: '更新されました。',
  times: '回',
  userDirect: 'ユーザダイレクト',
  deadline: '期限',
  end: '終了',
  groupSetting: 'グループ設定',
  privatePost: '非公開の投稿',
  privatePostNew: '非公開の投稿も含める',
  privateGroup: '非公開のグループ',
  invitationGroup: '招待制のグループ',
  addGroup: '新規登録',
  creator: '作成者',
  lastUpdateTime: '最終更新日時',
  groupRegistation: 'グループの新規登録',
  groupEditting: 'グループの編集',
  teleworkStudyGroup: 'テレワーク研究会',
  groupCreated: 'グループを作成しました。',
  groupUpdated: 'グループを更新しました。',
  serverErrorWatfCharm: 'リクエストエラーが発生しました。\nしばらく経ってからアクセスしてください。',
  serverErrorTransction: 'システムエラーが発生いたしました。\nエラー発生の原因と対処方法について調査いたしますので、\nお手数ですが、ヘルプセンターまでご連絡ください。',
  serveOffline: 'ネットワークが切断されました。\nしばらく経ってからアクセスしてください。',
  serverError1: 'サーバエラーが発生しました。',
  serverError2: 'しばらく経ってからアクセスしてください。',
  serverError3: 'グループの退出に失敗しました。',
  serverError4: '選択されたグループは存在しません。',
  serverIpRestrictError: '該当コミュニティへのアクセスはIPアドレスにて制限されております。\nコミュニティ管理者へご確認をお願いします。\n',
  invitedError: 'アクセスしたURLは無効です。',
  invited: 'に参加登録',
  invited1: '参加登録',
  precautions: 'の注意事項',
  invitedCodeNotFound: '招待コードが無効です。',
  invitedGroupUrlNotFound: 'グループ招待URLが無効です。',
  INVALID_URL_ERROR: 'アクセスしたURLは無効です。',
  invalidInvitationUrl: 'アクセスしたURLは無効です。再度正しいURLからアクセスください。',
  expiredUrl: 'アクセスしたURLは有効期限切れです。',
  errorParticipated: '既に該当のコミュニティに参加済みです。',
  errorMaxUser: 'ユーザ登録数の上限に達しています。\nコミュニティ管理者にお問い合わせください。',
  minDate: '%n年%n月%n日以降の日付を入力してください。',
  minDateMonth: '%n年%n月以降の日付を入力してください。',
  myPost: 'マイ投稿',

  join: '参加する',
  haveNoGroup: 'グループはありません',
  errorDeleteGroup: 'グループ削除失敗しました。',
  errorDeleteGroupUser: 'グループの削除に失敗しました。',
  errorCreateGroup: 'グループ作成失敗しました。',
  errorEditGroup: 'グループ修正失敗しました。',
  errorLeaveGroup: 'グループの退出ができませんでした。',
  participantGroup: '参加者',
  validateSpace: 'パスワードはスペースを許可しません',
  toLoginScreen: 'ログイン',
  postTitleSearch: '投稿タイトル',
  username: 'ユーザ名',
  listGroup: 'グループ一覧',
  communityEditGroup: 'グループ編集',
  postingType: '投稿種別',
  postType: '種別',
  comment: 'コメント',
  controlNumber: '管理番号',
  dateSubmission: '投稿日時',
  dateSubmissionUserPost: '投稿日時',
  validateFileNotFound: 'CSVファイルが指定されていません。',
  validateDuplicateEmail: 'ご入力いただいた%sは既に登録されています。',
  hours: '時',
  minutes: '分',
  talkRoomDetail: 'トークルーム詳細',
  listTalkrooms: 'トークルーム一覧',
  contractInformation: '契約情報',
  createNewPost: '投稿する',
  support: 'サポート',
  areYouSure: 'ログアウトしますか？',
  errorFileUpload: '指定されたファイルの拡張子でアップロードしてください。',
  postJob: '※求人はアルムナイのみに表示されます。\n(在籍ユーザには表示されません)',
  postImageAnnotation1: '※アップロード可能なファイル形式：.pdf／.jpg／.jpeg／.png',
  postImageAnnotation2: '※「20MB」以内のファイル1点のみ',

  errorComment: 'コメントは1文字以上、500文字以下で入力してください。',
  ngKeyWord: '不適切な表現を含む言葉が入力されています。',

  createTalkRooms: '新規登録',
  validateTypeImage: '指定された拡張子のファイルをアップロードしてください。',
  validateFileSize: 'ファイルは%nMB以下でアップロードしてください。',
  privateTalkrooms: '非公開のトークルーム',
  deleteTalkRoom: 'トークルーム削除',
  keepPrivate: '非公開にする',
  makePublic: '公開にする',
  keepPublic: '公開に戻す',
  keepPublicTalkRooms: 'コメントを公開',
  keepConversationsPrivate: 'トークを非公開',
  publicYourTalk: 'トークを公開',
  zz10_404_title: 'ページがみつかりません',
  zz10_404_content: 'お探しのページは移動または削除された可能性があります。',
  zz15_500_title: 'ページが表示できません',
  zz15_500_content1: 'ご不便をおかけ申し訳ございません。',
  zz15_500_content2: '技術的な問題が発生して、ページが表示されませんでした。',
  zz15_500_content3: 'しばらく時間をおいてから、再度操作をお願いいたします。',
  zz15_500_button: 'トップ画面へ',
  zz20_503_title: '現在メンテナンス中です',
  zz20_503_content1: '日頃よりYELLoopをご利用いただき、誠にありがとうございます。',
  zz20_503_content2: '現在システムメンテナンスのため、こちらのページはご利用いただけません。',
  zz20_503_content3: 'ご不便をおかけいたしますが、ご理解いただきますようお願い申し上げます。',
  zz20_503_content4: 'メンテナンス期間',
  zz20_503_content5: '※メンテナンス期間は延長される可能性があります。',
  VALIDATE_CURRENT_DATE_TIME: '現在から%n分後以降の日時を設定してください。',
  VALIDATE_FILE_EXTENSION: '指定された拡張子のファイルをアップロードしてください。',
  CsvFormat: 'CSVフォーマット',
  VALIDATE_FILE_NOTFOUND: 'ファイルが選択されていません。',
  seeMore: 'もっと見る',
  ERROR_UPLOAD_MAX_NUMBER: '一度にアップロードできる上限数（100件）を超えています。', // 2022/12/7 CW-TrungNT Fix UAT#5645: OE80 (Ph2-R20221222)
  ERROR_UPLOAD_FILE_FORMAT: 'アップロードファイルのフォーマットが正しくありません。',
  VALIDATE_INVALID_FILE_FORMAT: 'ファイルのフォーマットが不正です。',
  VALIDATE_DUPLICATE_UPLOAD: 'ファイル内に同じメールアドレスが入力されています。',
  VALIDATE_NG_WOLDS: '不適切な表現を含む言葉が入力されています。',
  VALIDATE_NOT_CHANGED: '変更前と異なる値を入力してください',
  VALIDATE_CURRENT_PASSWORD: 'パスワードの認証に失敗しました。',
  VALIDATE_OWNED_POINTS: '付与するポイントが保有ポイントを超えています。ポイントの購入をご検討ください。',
  VALIDATE_USER_DUPLICATE:
    '該当コミュニティに在籍者またはアルムナイとしてのアカウントをお持ちの場合は、同じメールアドレスでアカウントを作成することができません。\n別のメールアドレスをご利用ください。',
  VALIDATE_DUPLICATE: 'ご入力いただいたメールアドレスは既に登録されています。',
  // 2022/10/21 CW-TrungNT Fix UAT#4289 (Ph2-R20221021)
  VALIDATE_COMUNITY_AND_ALUMUNAI_DUPLICATE: 'コミュニティ内に同じメールアドレスでアカウントが登録されております。\n別のメールアドレスをご利用ください。',
  // 2022/10/21 CW-TrungNT Fix UAT#4289 (Ph2-R20221021)
  VALIDATE_NUMBER_OF_USERS: 'ユーザの登録数の上限に達しています。\nコミュニティ管理者にお問い合わせください。',
  VALIDATE_MAX_TAGS: 'タグを5個以上設定することはできません。',
  VALIDATE_SAME_TAGS: '同じタグは入力できません。',
  VALIDATE_MAX_UPLOAD_IMAGE: '投稿できる画像の上限数（20）を超えています。',
  ERROR_CREATE: '登録に失敗しました。',
  FORBIDDEN_OPERATION: 'この操作は管理者によってブロックされています。',
  ERROR_ADDITIONAL_INFORMATIONS: '管理者によって更新された可能性がございます。お手数ですがブラウザを更新の上、再度操作してください。',
  DEFAULT_ERROR_MESSAGE: '予期しないエラーが発生しました。',
  ERROR_REGIST: 'ユーザの登録に失敗しました。',
  createPostSuccess: '投稿しました。',
  createPostFailed: '投稿に失敗しました。',
  deletePostContent: '削除します。',
  copyRight: 'Copyright © Mynavi Corporation',
  privacy: '個人情報保護方針',
  company: '運営会社',
  inquiry2: 'お問い合わせ',
  membership: '会員規約',
  termsOfService: '利用規約',
  notes: '注意事項',
  changeEmailSuccess: 'メールアドレスの変更が完了しました。',
  changeEmailError: 'メールアドレスの変更が失敗しました。',
  inputEmailNew: '新しいメールアドレスでログインしてください。',
  titleResetEmail: 'メールアドレスの再設定',
  requestTimeOut: '接続がタイムアウトしました。',
  waitAndTryAgain: '時間を空けて再度お試しください。',
  afterStartDateHour: '掲載開始日時より未来日を設定してください。',
  titleMentionTalkRoom: '※管理者およびトークルームに参加しているユーザにメンションできます。',
  titleMentionPost: '※メンション先に指定可能なユーザは投稿者及び、投稿にコメントしているのみとなします。',
  titleMentionCT10: '※投稿にコメントしているユーザに対してのみメンションすることができます。',
  titleMentionOF: '※投稿者及び、投稿にコメントしているユーザに対してのみメンションすることができます。',
  titleMentionPostDetail: '※メンション先に指定可能なユーザは、管理者、投稿者、投稿にコメントしているユーザです。',
  EMAIL_REGISTER_CONFIRM_ERROR: '既に同じメールアドレスが登録されているか、アクセスしたURLが間違っています。',
  //2022/11/16 TienNV Fix for UAT Phase2#4726: CI10 (Ph2-R20221116)
  COMMUNITY_PARTICIPATION_USER_APPROVAL_ERROR: '承認待ちのアルムナイユーザが承認できません。',
  VALIDATE_COMUNITY_AND_ALUMUNAI_DUPLICATE_LIST: 'コミュニティ内に同じメールアドレスでアカウントが登録されております。\n別のメールアドレスをご利用ください。',
  VALIDATE_WITHDRAWING_USERS: '既にサービスを退会しているユーザです。',
  VALIDATE_NUMBER_OF_USERS_FOR_ADMIN: 'ユーザ登録数の上限に達しています。\n契約情報ご確認の上、プラン変更をご希望の場合は、\nYELLoopカスタマーサポ―トにお問い合わせください。',
  VALIDATE_COMUNITY_AND_ALUMUNAI_DUPLICATE_LIST_FOR_ADMIN: 'コミュニティ内に同じメールアドレスでアカウントが登録されております。\n申請者に確認をお願いいたします。',
  VALIDATE_ALREADY_UPDATED_USER: '情報が更新された可能性がございます。\n再度対象を確認の上、操作を行ってください。',
  ERROR_INVITATION_GROUP_URL_CANNOT_BE_USED: 'メッセージ一括送信では「#グループ招待URL#」の利用はできません。',
  ERROR_INVITATION_GROUP_URL_NOT_FOUND: '招待制グループ案内では「#グループ招待URL#」の利用が必須です。',
  //custom
  ERROR_SETTINGS_ALREADY_UPDATED: '管理者によって更新された可能性がございます。\nお手数ですがブラウザを更新の上、再度操作してください。',
  VALIDATE_NOT_INPUT_URL: 'URLが入力されていません。',
  VALIDATE_INVALID_URL: '不正なURLです。',
  ERROR_NUMBER_OF_SELECTIONS_THRESHOLD_EXCEEDED: '一度に出力できる上限数を超えています。\n1,000件以下となるよう再選択の上、操作してください。',
  VALIDATE_WITHOUT_CONTENT: 'メッセージボードの内容が入力されていません。',
  ERROR_STATUS_HAS_CHANGED_1: '対象の情報が更新されている可能性があります。',
  ERROR_STATUS_HAS_CHANGED_2: '最新の情報をご確認の上、再度操作してください。',
  ERROR_MENTION_USER_INVALID: 'メンションに失敗しました。',
  VALIDATE_NUMBER_OF_MENTION: '選択できるメンションの上限数（30）を超えています。',
  // Featured_3rd No.21 「コミュニティサイト記事一覧」画面
  communitySite: 'コミュニティサイト',

  ERROR_SOCIAL_ACCOUNT_LINKING: 'アカウントの連携に失敗しました。',
  ERROR_SOCIAL_ACCOUNT_ALREADY_LINKING: 'アカウントの連携に失敗しました。\nすでに他のアカウントと連携されています。',
  ACCESS_DENIED: 'ソーシャルに連携の許可がありません。',
  NO_AUTHORIZATION_CODE: 'ソーシャルに連携の許可がありません。',
  ERROR_LOGIN: 'ログインに失敗しました。',

  hokkaido: '北海道',
  aomori: '青森県',
  iwate: '岩手県',
  miyagi: '宮城県',
  akita: '秋田県',
  yamagata: '山形県',
  fukushima: '福島県',
  ibaraki: '茨城県',
  tochigi: '栃木県',
  gunma: '群馬県',
  saitama: '埼玉県',
  chiba: '千葉県',
  tokyo: '東京都',
  kanagawa: '神奈川県',
  niigata: '新潟県',
  toyama: '富山県',
  ishikawa: '石川県',
  fukui: '福井県',
  yamanashi: '山梨県',
  nagano: '長野県',
  gifu: '岐阜県',
  shizuoka: '静岡県',
  aichi: '愛知県',
  mie: '三重県',
  shiga: '滋賀県',
  kyoto: '京都府',
  osaka: '大阪府',
  hyogo: '兵庫県',
  nara: '奈良県',
  wakayama: '和歌山県',
  tottori: '鳥取県',
  shimane: '島根県',
  okayama: '岡山県',
  hiroshima: '広島県',
  yamaguchi: '山口県',
  tokushima: '徳島県',
  kagawa: '香川県',
  ehime: '愛媛県',
  kochi: '高知県',
  fukuoka: '福岡県',
  saga: '佐賀県',
  nagasaki: '長崎県',
  kumamoto: '熊本県',
  oita: '大分県',
  miyazaki: '宮崎県',
  kagoshima: '鹿児島県',
  okinawa: '沖縄県',
  abroad: '日本国外',
  postDetails: '投稿詳細',

  loginWithGoogle: 'Googleでログイン',
  loginWithFacebook: 'Facebookでログイン',
  loginWithLine: 'LINEでログイン',

  integrateWithGoogle: 'Google連携',
  integrateWithFacebook: 'Facebook連携',
  integrateWithLine: 'LINE連携',

  multipleSelectionsPossible: '【複数選択可】',

  ...jpRegister,
  ...jpForgotPassword,
  ...jpSentMail,
  ...jpResetPassword,
  ...jpAlumniRegistration,
  ...jpCompleteRegistration,
  ...jpRegisterAlumnusCommunity,
  ...jpRegisterCommunityFinished,
  ...jpLogin,
  ...jpAccount,
  ...jpJoinUsers,
  ...jpJoinUsersFinished,
  ...jpLobby,
  ...jpAccountEdit,
  ...jpLeaveCommunity,
  ...jpCancelYelloop,
  ...jpInquiry,
  ...jpCommunityForgotEmail,
  ...jpTermOfUses,
  ...jpNotificationCommunity,
  ...jpUserManagementFlag,
  ...jpListUserCommunity,
  ...jpGeneralUserCommunity,
  ...jpAlumniProfile,
  ...jpCommunityPortfolios,
  ...jpGroups,
  ...jpConversation,
  ...jpEditCommunity,
  ...jpUserManagement,
  ...jpAdminRegisterForm,
  ...jpCommunityCandidates,
  ...jpMessagesTemplate,
  ...jpContractInformation,
  ...jpSubmitResetAdmin,
  ...jpTalkRoom,
  ...jpDashboard,
  ...jpCommunityManagement,
  ...jpPoints,
  ...jpCommunitySite,
}
