import {
  AuthActions,
  AuthGetters,
  AuthMutations,
  RootActions,
  RootGetters,
  RootMutations,
  RegisterActions,
  RegisterGetters,
  RegisterMutations,
  UserGetters,
  UserMutations,
  UserActions,
  AccountGetters,
  AccountMutations,
  AccountActions,
  ConversationsGetters,
  ConversationsMutations,
  ConversationsActions,
  UserManagementGetters,
  UserManagementMutations,
  UserManagementActions,
  CommunityGetters,
  CommunityMutations,
  CommunityActions,
  CommunityPortfoliosActions,
  CommunityPortfoliosGetters,
  CommunityPortfoliosMutations,
  PostGetters,
  PostMutations,
  PostActions,
  GroupsGetters,
  GroupsMutations,
  GroupsActions,
  InquiryActions,
  InquiryGetters,
  InquiryMutations,
  AlumniRegisterAdminGetters,
  AlumniRegisterAdminActions,
  AlumniRegisterAdminMutations,
  ChatGetters,
  ChatActions,
  ChatMutations,
  UserCandidatesGetters,
  UserCandidatesMutations,
  UserCandidatesActions,
  CommunityAlumnusActions,
  CommunityAlumnusGetters,
  CommunityAlumnusMutations,
  CommunityUsersGetters,
  CommunityUsersActions,
  CommunityUsersMutations,
  UserPostGetters,
  UserPostActions,
  UserPostMutations,
  ContractInformationGetters,
  ContractInformationActions,
  ContractInformationMutations,
  CommunityChatListActions,
  CommunityChatListGetters,
  CommunityChatListMutations,
  PointGetters,
  PointActions,
  PointMutations,
  DashboardGetters,
  DashboardActions,
  DashboardMutations,
  CommunitySiteAdminGetters,
  CommunitySiteAdminActions,
  CommunitySiteAdminMutations,
  CommunitySiteGetters,
  CommunitySiteMutations,
  CommunitySiteActions,
} from './enum'

export const ROOT_STORE = {
  GETTERS: RootGetters,
  MUTATIONS: RootMutations,
  ACTIONS: RootActions,
}

export const AUTH_STORE = {
  GETTERS: AuthGetters,
  MUTATIONS: AuthMutations,
  ACTIONS: AuthActions,
}

export const REGISTER_STORE = {
  GETTERS: RegisterGetters,
  MUTATIONS: RegisterMutations,
  ACTIONS: RegisterActions,
}

export const USER_STORE = {
  GETTERS: UserGetters,
  MUTATIONS: UserMutations,
  ACTIONS: UserActions,
}

export const ACCOUNT_STORE = {
  GETTERS: AccountGetters,
  MUTATIONS: AccountMutations,
  ACTIONS: AccountActions,
}
export const CONVERSATIONS_STORE = {
  GETTERS: ConversationsGetters,
  MUTATIONS: ConversationsMutations,
  ACTIONS: ConversationsActions,
}
export const USER_MANAGEMENT_STORE = {
  GETTERS: UserManagementGetters,
  MUTATIONS: UserManagementMutations,
  ACTIONS: UserManagementActions,
}

export const COMMUNITY_STORE = {
  GETTERS: CommunityGetters,
  MUTATIONS: CommunityMutations,
  ACTIONS: CommunityActions,
}

export const COMMUNITY_PORTFOLIOS_STORE = {
  GETTERS: CommunityPortfoliosGetters,
  MUTATIONS: CommunityPortfoliosMutations,
  ACTIONS: CommunityPortfoliosActions,
}

export const POST_STORE = {
  GETTERS: PostGetters,
  MUTATIONS: PostMutations,
  ACTIONS: PostActions,
}
export const GROUPS_STORE = {
  GETTERS: GroupsGetters,
  MUTATIONS: GroupsMutations,
  ACTIONS: GroupsActions,
}

export const INQUIRY_STORE = {
  GETTERS: InquiryGetters,
  ACTIONS: InquiryActions,
  MUTATIONS: InquiryMutations,
}

export const ALUMNI_REGISTER_FORM_STORE = {
  GETTERS: AlumniRegisterAdminGetters,
  ACTIONS: AlumniRegisterAdminActions,
  MUTATIONS: AlumniRegisterAdminMutations,
}
export const CHAT_STORE = {
  GETTERS: ChatGetters,
  ACTIONS: ChatActions,
  MUTATIONS: ChatMutations,
}

export const COMMUNITY_ALUMNUS_CANDIDATES_STORE = {
  GETTERS: UserCandidatesGetters,
  ACTIONS: UserCandidatesActions,
  MUTATIONS: UserCandidatesMutations,
}

export const COMMUNITY_ALUMNUS_STORE = {
  GETTERS: CommunityAlumnusGetters,
  ACTIONS: CommunityAlumnusActions,
  MUTATIONS: CommunityAlumnusMutations,
}

export const COMMUNITY_USERS_STORE = {
  GETTERS: CommunityUsersGetters,
  ACTIONS: CommunityUsersActions,
  MUTATIONS: CommunityUsersMutations,
}

export const USER_POST_STORE = {
  GETTERS: UserPostGetters,
  ACTIONS: UserPostActions,
  MUTATIONS: UserPostMutations,
}

export const CONTRACT_INFORMATION_STORE = {
  GETTERS: ContractInformationGetters,
  ACTIONS: ContractInformationActions,
  MUTATIONS: ContractInformationMutations,
}

export const COMMUNITY_CHAT_LIST_STORE = {
  GETTERS: CommunityChatListGetters,
  ACTIONS: CommunityChatListActions,
  MUTATIONS: CommunityChatListMutations,
}

export const POINT_STORE = {
  GETTERS: PointGetters,
  ACTIONS: PointActions,
  MUTATIONS: PointMutations,
}

export const DASHBOARD_STORE = {
  GETTERS: DashboardGetters,
  ACTIONS: DashboardActions,
  MUTATIONS: DashboardMutations,
}

export const COMMUNITY_SITE_ADMIN_STORE = {
  GETTERS: CommunitySiteAdminGetters,
  ACTIONS: CommunitySiteAdminActions,
  MUTATIONS: CommunitySiteAdminMutations,
}
export const COMMUNITY_SITE_STORE = {
  GETTERS: CommunitySiteGetters,
  MUTATIONS: CommunitySiteMutations,
  ACTIONS: CommunitySiteActions,
}
