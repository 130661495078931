import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'
import LayoutAdmin from '@/components/layouts/layout-admin/index.vue'
import {
  URL_ARTICLE_LIST_PATH,
  URL_ARTICLE_CREATE_PATH,
  URL_ARTICLE_CREATE_CONFIRM_PATH,
  URL_ARTICLE_EDIT_PATH,
  ARTICLE_TITLE_FIELD_NAME,
  URL_ARTICLE_EDIT_CONFIRM_PATH,
} from '@/views/community-admin/community-site/constants'
import { useStore } from 'vuex'
import { COMMUNITY_SITE_ADMIN_STORE, COMMUNITY_STORE } from '@/store/constants'
import { store } from '@/store'
import communitySiteAdmin, { ARTICLE_CREATING } from '@/store/modules-community-admin/community-site'

/**
 * Check from store
 * if this community has community_site_available, allow to be redirect
 * else -> direct target page to 404
 */
const checkAndHandleCommunitySitePermission = async (communityId, next) => {
  if (!store.state.community.id) {
    await store.dispatch(COMMUNITY_STORE.ACTIONS.SET_COMMUNITY_INFO, communityId)
  }
  if (!store.state.community.community_site_available) {
    next({
      replace: true,
      path: '/404',
    })
    return false
  }
  return true
}

export default (): RouteRecordRaw[] => {
  return [
    {
      path: URL_ARTICLE_LIST_PATH(':id'),
      name: 'Community Site List',
      component: () => import('@/views/community-admin/community-site/community_site_posts/index.vue'),
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'CK10',
        layout: LayoutAdmin,
      },
      beforeEnter: async (to, from, next) => {
        const hasPermission = await checkAndHandleCommunitySitePermission(to.params.id, next)
        if (hasPermission) {
          next()
        }
      },
    },
    {
      path: URL_ARTICLE_CREATE_PATH(':id'),
      name: "CommunitySite's article creation",
      component: () => import('@/views/community-admin/community-site/article-create/index.vue'),
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'CK20',
        layout: LayoutAdmin,
      },
      beforeEnter: async (to, from, next) => {
        const hasPermission = await checkAndHandleCommunitySitePermission(to.params.id, next)
        if (hasPermission) {
          if (from && from.path !== URL_ARTICLE_CREATE_CONFIRM_PATH(to.params.id.toString())) {
            await store.dispatch(COMMUNITY_SITE_ADMIN_STORE.ACTIONS.CLEAR_FORM_ARTICLE_DATA)
          }
          next()
        }
      },
    },
    {
      path: URL_ARTICLE_CREATE_CONFIRM_PATH(':id'),
      name: "CommunitySite's article create confirm",
      component: () => import('@/views/community-admin/community-site/article-confirm/index.vue'),
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'CK30',
        layout: LayoutAdmin,
      },
      beforeEnter: async (to, from, next) => {
        const hasPermission = await checkAndHandleCommunitySitePermission(to.params.id, next)
        if (hasPermission) {
          next()
        }
      },
    },
    {
      path: URL_ARTICLE_EDIT_PATH(':id', ':idArticle'),
      name: "CommunitySite's article edit",
      component: () => import('@/views/community-admin/community-site/article-create/index.vue'),
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'CM10',
        layout: LayoutAdmin,
      },
      beforeEnter: async (to, from, next) => {
        const hasPermission = await checkAndHandleCommunitySitePermission(to.params.id, next)
        if (hasPermission) {
          next()
        }
      },
    },
    {
      path: URL_ARTICLE_EDIT_CONFIRM_PATH(':id', ':idArticle'),
      name: "CommunitySite's article confirm edit",
      component: () => import('@/views/community-admin/community-site/article-confirm/index.vue'),
      meta: {
        title: translate('titleHeadCommunityAdmin'),
        screenId: 'CM20',
        layout: LayoutAdmin,
      },
      beforeEnter: async (to, from, next) => {
        const hasPermission = await checkAndHandleCommunitySitePermission(to.params.id, next)
        if (hasPermission) {
          next()
        }
      },
    },
  ]
}
