import { mutations } from './account.mutations'
import { getters } from './account.getters'
import { actions } from './account.actions'

import { Module } from 'vuex'
import { RootState } from '@/store/store.type'
import { AccountStateTypes } from './account.types'

export const state: AccountStateTypes = {
  editNormalData: {
    family_name: '',
    given_name: '',
    family_name_kana: '',
    given_name_kana: '',
    birthday: '',
    secondary_password: '',
  },
  emailData: {
    email: '',
    emailConfirm: '',
  },
  changeEmail: false,
  changePassword: false,
  passwordData: {
    password: '',
    confirmPassword: '',
  },
  profileDetail: {},
  showModal: false,
  point_history: {},
  userWithDeleted: {},
}

const account: Module<AccountStateTypes, RootState> = {
  // namespaced: true,
  state,
  mutations,
  actions,
  getters,
}

export default account
