import axios, { AxiosResponse } from 'axios'

const list = async (community_id: number, params: any): Promise<AxiosResponse> => axios.get(`/communities/${community_id}/posts/community_site`, { params })

const detail = async (post_id: string, params: any): Promise<AxiosResponse> =>
  axios.get(`/posts/${post_id}/detail`, {
    params: params,
  })

const listComment = async (community_id: number, params: any): Promise<AxiosResponse> =>
  axios.get(`/communities/${community_id}/list/comment`, {
    params: params,
  })

const createComment = async (comment: CommentType, post_id: number): Promise<AxiosResponse> => axios.post(`posts/${post_id}/comments`, comment)

const deleteComment = async (post_id: string, post_comment_id: string, params: any): Promise<AxiosResponse> =>
  axios.delete(`posts/${post_id}/comments/${post_comment_id}`, { data: params })

const likePost = async (post_id: number): Promise<AxiosResponse> => axios.put(`/posts/${post_id}/like`)

const disLikePost = async (post_id: number): Promise<AxiosResponse> => axios.delete(`/posts/${post_id}/like`)

const getUserMentionPost = async (post_id: number): Promise<AxiosResponse> => axios.get(`posts/${post_id}/mention_users`)

const communitySiteService = {
  list,
  detail,
  listComment,
  deleteComment,
  createComment,
  likePost,
  disLikePost,
  getUserMentionPost,
}

export default communitySiteService

export type CommentType = {
  comment: string
}
