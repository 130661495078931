import communityAlumnusServices from '@/services/community-alumnus.services'
import { COMMUNITY_ALUMNUS_STORE } from '@/store/constants'

type CommitType = {
  commit: any
}

export const actions = {
  async [COMMUNITY_ALUMNUS_STORE.ACTIONS.SET_LIST_COMMUNITY_ALUMNUS]({ commit }: CommitType, payload: any): Promise<void> {
    commit(COMMUNITY_ALUMNUS_STORE.MUTATIONS.LOADING, true)
    try {
      const response = await communityAlumnusServices.getListAlumnusApi({ id: payload?.id, params: payload?.params, data: payload?.data })

      if (response.data?.success) {
        commit(COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_LIST_COMMUNITY_ALUMNUS, {
          listCommunityAlumnus: response.data.data,
          total_pages: response.data.total_pages,
          total_records: response.data.total_records,
        })
      }
      commit(COMMUNITY_ALUMNUS_STORE.MUTATIONS.LOADING, false)
    } catch (error) {
      console.log('error', error)
      commit(COMMUNITY_ALUMNUS_STORE.MUTATIONS.LOADING, false)
    }
  },

  async [COMMUNITY_ALUMNUS_STORE.ACTIONS.SET_DETAIL_ALMNUS]({ commit }: CommitType, payload: any): Promise<void> {
    commit(COMMUNITY_ALUMNUS_STORE.MUTATIONS.LOADING, true)

    try {
      const response = await communityAlumnusServices.getAlmunusDetailApi({ id: payload?.id, idAlumni: payload?.idAlumni })

      await commit(COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_DETAIL_ALMNUS, response.data.data)
      await commit(COMMUNITY_ALUMNUS_STORE.MUTATIONS.LOADING, false)
    } catch (error) {
      await commit(COMMUNITY_ALUMNUS_STORE.MUTATIONS.LOADING, false)
      await commit(COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_DETAIL_ALMNUS, { errorServer: true })

      console.log(error)
    }
  },

  async [COMMUNITY_ALUMNUS_STORE.ACTIONS.SET_LIST_MANAGING_FLAGS]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const response = await communityAlumnusServices.getListManagingFlagsApi(payload)
      commit(COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_LIST_MANAGING_FLAGS, response.data.data)
    } catch (error) {
      console.log(error)
    }
  },

  [COMMUNITY_ALUMNUS_STORE.ACTIONS.SET_ALUMNAI_SELECTED]({ commit }: CommitType, payload: any): void {
    commit(COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_ALUMNAI_SELECTED, payload)
  },

  [COMMUNITY_ALUMNUS_STORE.ACTIONS.SET_LIST_RECORD_SELECTED]({ commit }: CommitType, payload: boolean): void {
    commit(COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_LIST_RECORD_SELECTED, payload)
  },

  [COMMUNITY_ALUMNUS_STORE.ACTIONS.UPDATE_DETAIL_ALMUNUS]({ commit }: CommitType, payload: any): void {
    commit(COMMUNITY_ALUMNUS_STORE.MUTATIONS.UPDATE_DETAIL_ALMUNUS, payload)
  },

  [COMMUNITY_ALUMNUS_STORE.ACTIONS.SET_DATA_SEARCH_ALUMNUS]({ commit }: CommitType, payload: any): void {
    commit(COMMUNITY_ALUMNUS_STORE.MUTATIONS.SET_DATA_SEARCH_ALUMNUS, payload)
  },
}
