import axios, { AxiosResponse } from 'axios'

const getPosts = async (community_id: number, params: GetPostType): Promise<AxiosResponse> =>
  axios.get(`/communities/${community_id}/posts`, {
    params: params,
  })

const getLikes = async (post_id: string, params: any): Promise<AxiosResponse> => axios.get(`/posts/${post_id}/likes`, { params })

const getInterest = async (post_id: string, params: any): Promise<AxiosResponse> => axios.get(`/posts/${post_id}/interests`, { params })

const deletePost = async (post_id: number): Promise<AxiosResponse> => axios.delete(`/posts/${post_id}`)

const likePost = async (post_id: number, params: any): Promise<AxiosResponse> => axios.put(`/posts/${post_id}/like`, params)

const interestPost = async (post_id: number, params: any): Promise<AxiosResponse> => axios.put(`/posts/${post_id}/interest`, params)

const disLikePost = async (post_id: number, params: any): Promise<AxiosResponse> => axios.delete(`/posts/${post_id}/like`, { params })

const disInterestPost = async (post_id: number, params: any): Promise<AxiosResponse> => axios.delete(`/posts/${post_id}/interest`, { params })

const createPost = async (newPost: GetPostType, community_id: number): Promise<any> => axios.post(`communities/${community_id}/posts`, newPost)

const updatePost = async (newPost: GetPostType, post_id: number): Promise<any> => axios.put(`/posts/${post_id}`, newPost)

const createComment = async (comment: CommentType, post_id: number): Promise<AxiosResponse> => axios.post(`posts/${post_id}/comments`, comment)

const deleteComment = async (post_id: string, post_comment_id: string, params: any): Promise<AxiosResponse> =>
  axios.delete(`posts/${post_id}/comments/${post_comment_id}`, { data: params })

const favoritePost = async (post_id: number, params: any): Promise<AxiosResponse> => axios.put(`posts/${post_id}/favorite`, params)

const unFavoritePost = async (post_id: number, params: any): Promise<AxiosResponse> => axios.delete(`posts/${post_id}/favorite`, { params })

const getUrlDowload = async (community_id: number, post_id: number): Promise<AxiosResponse> =>
  axios.get(`communities/${community_id}/download_file/${post_id}`, { responseType: 'blob' })

const getPostById = async ({ post_id, query }: { post_id: string; query: any }): Promise<AxiosResponse> =>
  axios.get(`posts/${post_id}`, {
    params: query,
  })

const listComment = async (community_id: number, params: GetPostType): Promise<AxiosResponse> =>
  axios.get(`/communities/${community_id}/list/comment`, {
    params: params,
  })

const getUserMentionPost = async (post_id: number, params?: any): Promise<AxiosResponse> => axios.get(`posts/${post_id}/mention_users`, { params })

const detailsPost = (post_id: string, params?: any): Promise<AxiosResponse> => axios.get(`/posts/${post_id}/detail`, { params })

const postService = {
  getPosts,
  getLikes,
  getInterest,
  deletePost,
  likePost,
  interestPost,
  disLikePost,
  disInterestPost,
  createPost,
  updatePost,
  createComment,
  deleteComment,
  favoritePost,
  unFavoritePost,
  getPostById,
  getUrlDowload,
  listComment,
  getUserMentionPost,
  detailsPost,
}

export default postService

export type GetPostType = {
  content_type?: 1 | 2 | 3 | 4
  tags?: string
  keyword?: string
  my_post?: boolean
}

export type NewPost = {
  content_type: number
  content: string
  title: string
  tags: Array<any>
}

export type CommentType = {
  comment: string
}
