import { COMMUNITY_SITE_STORE } from '@/store/constants'
import { RootState } from '@/store/store.type'
import { GetterTree } from 'vuex'
import { CSPGettersTypes, CSPStateTypes } from '.'
import { pick } from 'lodash'

export const getters: GetterTree<CSPStateTypes, RootState> & CSPGettersTypes = {
  [COMMUNITY_SITE_STORE.GETTERS.COMMUNITY_SITE_POSTS]: (state: CSPStateTypes): any => pick(state, 'posts', 'loadingPosts', 'total_pages', 'total_records'),
  [COMMUNITY_SITE_STORE.GETTERS.COMMUNITY_SITE_POST_DETAIL]: (state: CSPStateTypes): any => pick(state, 'post', 'loadingPosts'),
}
