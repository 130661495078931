import communitySiteServices from '@/services/community-site.service'
import { COMMUNITY_SITE_STORE } from '@/store/constants'

type CommitType = {
  commit: any
}

export const actions = {
  async [COMMUNITY_SITE_STORE.ACTIONS.GET_COMMUNITY_SITE_POSTS]({ commit }: CommitType, { community_id, params }: { community_id: number; params: any }): Promise<void> {
    commit(COMMUNITY_SITE_STORE.MUTATIONS.SET_LOADING_CSP, true)

    try {
      const data: any = await communitySiteServices.list(community_id, params)

      if (data.data?.success) {
        commit(COMMUNITY_SITE_STORE.MUTATIONS.SET_COMMUNITY_SITE_POSTS, { data: data.data?.data })
      }
    } catch (error: any) {
      console.log(error.response)
    }
  },

  async [COMMUNITY_SITE_STORE.ACTIONS.GET_COMMUNITY_SITE_POST_DETAIL]({ commit }: CommitType, payload: any): Promise<void> {
    commit(COMMUNITY_SITE_STORE.MUTATIONS.SET_LOADING_CSP, true)

    try {
      const data: any = await communitySiteServices.detail(payload.post_id, payload.params)

      if (data.data?.success) {
        commit(COMMUNITY_SITE_STORE.MUTATIONS.SET_COMMUNITY_SITE_POST_DETAIL, { data: data.data?.data })
      }
    } catch (error: any) {
      console.log(error.response)
    }
  },

  async [COMMUNITY_SITE_STORE.ACTIONS.SET_LIST_COMMENT_CSP]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data = await communitySiteServices.listComment(payload?.community_id, payload?.params)
      if (data.data?.success) {
        commit(COMMUNITY_SITE_STORE.MUTATIONS.SET_LIST_COMMENT_CSP, { data: data.data.data, postId: payload?.params.post_id })
      }
    } catch (error) {
      console.log(error)
    }
  },

  async [COMMUNITY_SITE_STORE.ACTIONS.SET_COMMENT_CSP]({ commit }: CommitType, { comment, post_id }: { comment: any; post_id: number }): Promise<void> {
    try {
      const data = await communitySiteServices.createComment(comment, post_id)
      if (data.data?.success) {
        commit(COMMUNITY_SITE_STORE.MUTATIONS.SET_COMMENT_CSP, Object.assign(data.data?.data, { my_comment: true }))
      }
    } catch (error) {
      console.log(error)
    }
  },

  async [COMMUNITY_SITE_STORE.ACTIONS.DELETE_COMMENT_CSP](
    { commit }: CommitType,
    { postId, postCommentId, params }: { postId: string; postCommentId: string; params: any }
  ): Promise<any> {
    try {
      const data: any = await communitySiteServices.deleteComment(postId, postCommentId, params)
      if (data.data?.success) {
        commit(COMMUNITY_SITE_STORE.MUTATIONS.DELETE_COMMENT_CSP, { postCommentId: postCommentId })
        return {
          success: true,
        }
      }
      return {
        success: false,
        message_code: data?.response?.data?.data[0]?.message_code,
      }
    } catch (error) {
      console.log(error)
      return {
        success: false,
        message_code: null,
      }
    }
  },

  async [COMMUNITY_SITE_STORE.ACTIONS.UPDATE_LIKE_CSP]({ commit }: CommitType, params: any): Promise<void> {
    try {
      commit(COMMUNITY_SITE_STORE.MUTATIONS.UPDATE_LIKE_CSP, params)
    } catch (error) {
      console.log(error)
    }
  },

  async [COMMUNITY_SITE_STORE.ACTIONS.GET_USER_MENTION_CSP]({ commit }: CommitType, post_id: number): Promise<void> {
    try {
      const res = await communitySiteServices.getUserMentionPost(post_id)

      if (res.data?.success) {
        commit(COMMUNITY_SITE_STORE.MUTATIONS.SET_USER_MENTION_CSP, res.data)
      }
    } catch (error) {
      console.log(error)
    }
  },
}
