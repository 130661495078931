export const enContractInformation = {
  CP40currentPlan: 'Current plan',
  CP40noticeRenewDate: 'The contract renewal date is approaching.',
  CP40peopleChange: 'Those who wish to change the plan',
  CP40peopleCancel: 'Those who wish to cancel the service',
  CP40updateDate: 'Next update date',
  CP40contractHistory: 'Contract history',
  CP40maxMembers: 'Maximum number of participating members',
  CP40standardPlan: 'Standard Plan',
  CP40currentMembers: 'Current number of participating members',
  CP40noticeMaxMembers: 'The maximum number of users is approaching. Please consider upgrading your plan. ',
  CP40currentPossession: 'Current holdings',
  CP40yellNumber: 'Number of YELL points',
  CP40buyPoints: 'Buy points',
  CP40contractorInfor: 'Contractor Information',
  CP40wasedaUni: 'Waseda University',
  CP40peopleConfirm: 'For those who wish to confirm or change the contract details',
  CP40changeContractor: 'Change Contractor',
  CP40contractType: 'contract type',
  CP40contractDetail: 'Contract details',
  CP40validityPeriod: 'Contract period',
  CP40underContract: 'Under contract',
  CP40planNew: 'Plan New',
  CP40planContinue: 'Plan Continue',
  CP40planUpgrade: 'Plan Upgrade',
  CP40currentOption: 'Current option',
  CP40ChangeOption: 'If you would like to change your options',
  CP40OptionContractRenewalMessage: 'The contract renewal date is approaching',
  CP40OptionContractNotFound: '現在契約しているオプションはありません。',
}
