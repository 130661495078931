import LayoutDefault from '@/components/layouts/layout-default/index.vue'
import LayoutEmployee from '@/components/layouts/layout-employee/index.vue'
import { RouteRecordRaw } from 'vue-router'
import { translate } from '@/languages/i18n'

export default (): RouteRecordRaw[] => {
  return [
    {
      path: '/alumni/communities/:id/community_site',
      name: 'alumni community site posts',
      component: () => import('@/views/user/community_site/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SH10',
        layout: LayoutDefault,
      },
    },
    {
      path: '/alumni/communities/:id/community_site_posts_details/:post_id',
      name: 'alumni community site posts detail',
      component: () => import('@/views/user/community_site/artical_detail/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SH20',
        layout: LayoutDefault,
      },
    },
    {
      path: '/community/communities/:id/community_site',
      name: 'community community site posts',
      component: () => import('@/views/user/community_site/index.vue'),
      props: true,
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SH10',
        layout: LayoutEmployee,
      },
    },
    {
      path: '/community/communities/:id/community_site_posts_details/:post_id',
      name: 'community community site posts detail',
      component: () => import('@/views/user/community_site/artical_detail/index.vue'),
      meta: {
        title: translate('titleHeadUser'),
        screenId: 'SH20',
        layout: LayoutEmployee,
      },
    },
  ]
}
