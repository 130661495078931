import userService from '@/services/auth.service'
import registerService from '@/services/register-alumnus.service'
import { REGISTER_STORE } from '@/store/constants'
import { translate } from '@/languages/i18n'

type CommitType = {
  commit: any
}

export const actions = {
  async [REGISTER_STORE.ACTIONS.SET_INVITE]({ commit }: CommitType, inviteCode: string): Promise<void> {
    if (inviteCode === '') {
      commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_COMMUNITY, 'INVALID_URL_ERROR')
    } else {
      try {
        const inviteResponse = await userService.getInvitedCommunityApi(inviteCode)

        if (inviteResponse?.response?.status === 400) {
          commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_COMMUNITY, inviteResponse.response?.data.data[0].message_code)
        } else {
          commit(REGISTER_STORE.MUTATIONS.SET_INVITE, inviteResponse.data.data)
        }
      } catch (error) {
        console.log('err', error)
      }
    }
  },
  async [REGISTER_STORE.ACTIONS.SET_COMMUNITY_REQUIRED_COLUMNS]({ commit }: CommitType, community_id: string): Promise<void> {
    try {
      const response = await registerService.getCommunityRequiredColumnsApi(community_id)
      commit(REGISTER_STORE.MUTATIONS.SET_COMMUNITY_REQUIRED_COLUMNS, response.data.data)
    } catch (error) {
      console.log('err', error)
    }
  },
  async [REGISTER_STORE.ACTIONS.SET_TERMS_SERVICE_YELLOOP]({ commit }: CommitType, user_type: string): Promise<void> {
    try {
      const response = await registerService.getTermsOfServicesYelloopApi(user_type)

      commit(REGISTER_STORE.MUTATIONS.SET_TERMS_SERVICE_YELLOOP, response.data.data)
    } catch (error) {
      console.log('err', error)
    }
  },
  async [REGISTER_STORE.ACTIONS.SET_TERMS_SERVICE_COMMUNITY]({ commit }: CommitType, community_id: any): Promise<void> {
    try {
      const response = await registerService.getTermsOfServicesCommunityApi(community_id)
      commit(REGISTER_STORE.MUTATIONS.SET_TERMS_SERVICE_COMMUNITY, response.data.data)
    } catch (error) {
      console.log('err', error)
    }
  },
  async [REGISTER_STORE.ACTIONS.SET_LIST_JOBS]({ commit }: CommitType): Promise<void> {
    try {
      const response = await registerService.getListJobs()
      commit(REGISTER_STORE.MUTATIONS.SET_LIST_JOBS, response.data.data)
    } catch (error) {
      console.log('err', error)
    }
  },
  [REGISTER_STORE.ACTIONS.SET_REGISTER_VALUE]({ commit }: CommitType, payload: boolean): void {
    commit(REGISTER_STORE.MUTATIONS.SET_REGISTER_VALUE, payload)
  },
  [REGISTER_STORE.ACTIONS.SET_REGISTER_COMMUNITY_VALUE]({ commit }: CommitType, payload: boolean): void {
    commit(REGISTER_STORE.MUTATIONS.SET_REGISTER_COMMUNITY_VALUE, payload)
  },
  async [REGISTER_STORE.ACTIONS.SET_INFO_ALUMNUS]({ commit }: CommitType, id: string | number): Promise<void> {
    try {
      const data = await registerService.getInfoAlumnus(id)
      if (data?.status === 200) {
        commit(REGISTER_STORE.MUTATIONS.SET_INFO_ALUMNUS, data?.data?.alumni_community_information)
        commit(REGISTER_STORE.MUTATIONS.SET_ALUMNUS_VALIDATE_DUPLICATE, '')
      }
      if (data?.response?.status === 400) {
        if (data?.response?.data?.data[0]?.message_code === 'VALIDATE_DUPLICATE') {
          commit(REGISTER_STORE.MUTATIONS.SET_ALUMNUS_VALIDATE_DUPLICATE, 'VALIDATE_DUPLICATE')
        } else {
          commit(REGISTER_STORE.MUTATIONS.SET_ALUMNUS_VALIDATE_DUPLICATE, 'ERROR_PARTICIPATED')
        }
      }
    } catch (error) {
      console.log('err', error)
    }
  },

  async [REGISTER_STORE.ACTIONS.SET_VALIDATE_POST_ALUMNUS]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data: any = await registerService.postAlumnus(payload)
      if (data?.status === 200) {
        commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_ALUMNUS, '')
      }
      if (data?.response?.status === 400) {
        switch (data?.response?.data?.data[0]?.message_code) {
          case 'VALIDATE_DUPLICATE':
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_ALUMNUS, 'VALIDATE_DUPLICATE')
            break
          case 'VALIDATE_COMUNITY_AND_ALUMUNAI_DUPLICATE':
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_ALUMNUS, 'VALIDATE_COMUNITY_AND_ALUMUNAI_DUPLICATE')
            break
          case 'ERROR_PARTICIPATED':
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_ALUMNUS, 'ERROR_PARTICIPATED')
            break
          case 'VALIDATE_NUMBER_OF_USERS':
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_ALUMNUS, 'VALIDATE_NUMBER_OF_USERS')
            break
          case 'INVALID_URL_ERROR':
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_ALUMNUS, 'INVALID_URL_ERROR')
            break
          case 'ERROR_INVALID_PARAMS':
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_ALUMNUS, 'ERROR_INVALID_PARAMS')
            break
          case 'ERROR_ADDITIONAL_INFORMATIONS':
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_ALUMNUS, 'ERROR_ADDITIONAL_INFORMATIONS')
            break
          default:
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_ALUMNUS, 'REGISTER_FAILED')
        }
      }
    } catch (error) {
      console.log('err', error)
    }
  },

  async [REGISTER_STORE.ACTIONS.SET_VALIDATE_POST_COMMUNITY]({ commit }: CommitType, payload: any): Promise<void> {
    try {
      const data: any = await registerService.postAlumnusCommunity(payload, payload?.community_id)
      if (data?.status === 200) {
        commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_COMMUNITY, '')
      }
      if (data?.response?.status === 400) {
        switch (data?.response?.data?.data[0]?.message_code) {
          case 'VALIDATE_DUPLICATE':
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_COMMUNITY, 'VALIDATE_DUPLICATE')
            break
          case 'VALIDATE_COMUNITY_AND_ALUMUNAI_DUPLICATE':
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_COMMUNITY, 'VALIDATE_COMUNITY_AND_ALUMUNAI_DUPLICATE')
            break
          case 'ERROR_PARTICIPATED':
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_COMMUNITY, 'ERROR_PARTICIPATED')
            break
          case 'VALIDATE_NUMBER_OF_USERS':
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_COMMUNITY, 'VALIDATE_NUMBER_OF_USERS')
            break
          case 'INVALID_URL_ERROR':
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_COMMUNITY, 'INVALID_URL_ERROR')
            break
          case 'ERROR_INVALID_PARAMS':
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_COMMUNITY, 'ERROR_INVALID_PARAMS')
            break
          case 'ERROR_ADDITIONAL_INFORMATIONS':
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_COMMUNITY, 'ERROR_ADDITIONAL_INFORMATIONS')
            break
          default:
            commit(REGISTER_STORE.MUTATIONS.SET_VALIDATE_POST_COMMUNITY, 'REGISTER_FAILED')
        }
      }
    } catch (error) {
      console.log('err', error)
    }
  },
}
