import { GetterTree } from 'vuex'
import { AccountState } from '@/store/store.type'

import { ACCOUNT_STORE } from '@/store/constants'
import { AccountGettersTypes, AccountStateTypes } from './account.types'
import { pick } from 'lodash'

export const getters: GetterTree<AccountStateTypes, AccountState> & AccountGettersTypes = {
  [ACCOUNT_STORE.GETTERS.EDIT_NORMAL_DATA]: (state: AccountStateTypes): any => state.editNormalData,
  [ACCOUNT_STORE.GETTERS.EMAIL_DATA]: (state: AccountStateTypes): any => state.emailData,
  [ACCOUNT_STORE.GETTERS.PASSWORD_DATA]: (state: AccountStateTypes): any => state.passwordData,
  [ACCOUNT_STORE.GETTERS.PROFILE_DETAIL]: (state: AccountStateTypes): any => pick(state, 'profileDetail', 'showModal'),
  [ACCOUNT_STORE.GETTERS.POINT_HISTORY]: (state: AccountStateTypes): any => state.point_history,
  [ACCOUNT_STORE.GETTERS.LIST_USER_WITH_DELETED]: (state: AccountStateTypes): any => state.userWithDeleted,
}
