import { InquiryModuleType } from './modules/inquiry/index'
import { PostModuleType } from './modules/post/index'
import { PointModuleType } from './modules/point/index'
import { CommunityModuleType } from './modules/community/index'
import { RootModuleType } from '@/store/modules/root/index'
import { RootState } from '@/store/store.type'
import VuexPersistence from 'vuex-persist'
import { createStore, ModuleTree, Module } from 'vuex'

import roots from './modules/root'
import userManagement, { UserManagementModuleType } from '@/store/modules-community-admin/user-management/index'
import userCandidates, { UserCandidatesModuleType } from '@/store/modules-community-admin/alumnus-candidates/index'

import auth, { AuthModuleType } from './modules/auth'
import register from './modules/register-alumnus'
import user from './modules/user'
import groups, { GroupsModuleType } from './modules/groups'
import account from './modules/account'
import conversations from './modules/conversations'
import community from '@/store/modules/community'
import communityPortfolios from '@/store/modules/communities-portfolios'
import post from '@/store/modules/post'
import inquiry from '@/store/modules/inquiry'
// import adminRegisterAlumni from '@/store/modules-community-admin/alumni-register-form'
import chat from '@/store/modules/chat'
import point from '@/store/modules/point'
import userPost, { UserPostModuleType } from '@/store/modules/user-post/index'

import { RegisterModuleType } from './modules/register-alumnus/register.types'
import { UserModuleType } from './modules/user/index'
// import { AdminRegisterAlumniModuleType } from '/modules-community-admin/alumni-register-form/index'
import adminRegisterAlumni, { AdminRegisterAlumniModuleType } from '@/store/modules-community-admin/alumni-register-form/index'
import communityAlumnus, { CommunityAlumnusModuleType } from '@/store/modules-community-admin/community-alumnus/index'
import communityUsers, { CommunityUsersModuleType } from './modules-community-admin/community-users'
import contractInformations, { ContractInformationModuleType } from './modules-community-admin/contract-information'
import chatListHistories, { ChatListHistoriesModuleType } from './modules-community-admin/chat-list-histories'
import dashboard, { DashboardModuleType } from '@/store/modules-community-admin/dashboard'
import communitySiteAdmin, { CommunitySiteAdminModuleType } from '@/store/modules-community-admin/community-site'

import { CommunitySiteModuleType } from './modules/community-site/index'
import communitySite from './modules/community-site'

const vuexLocal = new VuexPersistence<RootState>({
  storage: window.localStorage,
  key: 'store',
  modules: ['auth'],
})

const modules: ModuleTree<RootState> = {
  roots,
  auth,
  register,
  user,
  account,
  conversations,
  userManagement,
  community,
  communityPortfolios,
  post,
  groups,
  inquiry,
  adminRegisterAlumni,
  chat,
  userCandidates,
  communityAlumnus,
  communityUsers,
  userPost,
  contractInformations,
  chatListHistories,
  point,
  dashboard,
  communitySite,
  communitySiteAdmin,
}

const root: Module<RootState, RootState> = {
  modules,
}

export const store = createStore<RootState>({
  ...root,
  plugins: [vuexLocal.plugin],
})

type StoreModules = {
  rootModule: RootModuleType
  authModule: AuthModuleType
  registerModule: RegisterModuleType
  userModule: UserModuleType
  userManagementModule: UserManagementModuleType
  community: CommunityModuleType
  post: PostModuleType
  groups: GroupsModuleType
  inquiry: InquiryModuleType
  adminRegisterAlumni: AdminRegisterAlumniModuleType
  userCandidatesModule: UserCandidatesModuleType
  communityAlumnusModule: CommunityAlumnusModuleType
  dashboardModule: DashboardModuleType
  communityUsersModule: CommunityUsersModuleType
  userPostModule: UserPostModuleType
  contractInformationModule: ContractInformationModuleType
  chatListHistoriesModule: ChatListHistoriesModuleType
  point: PointModuleType
  communitySiteAdminModule: CommunitySiteAdminModuleType
  // root: RootStoreModuleTypes;
  communitySiteModule: CommunitySiteModuleType
}

export type Store = RootModuleType<Pick<StoreModules, 'rootModule'>> &
  AuthModuleType<Pick<StoreModules, 'authModule'>> &
  CommunityModuleType<Pick<StoreModules, 'community'>> &
  PostModuleType<Pick<StoreModules, 'post'>> &
  CommunitySiteModuleType<Pick<StoreModules, 'communitySiteModule'>>
