<template>
  <!-- menu -->
  <div class="d-block pb-2 mb-2 pb-lg-4 mb-lg-4">
    <ul class="m-0 p-0">
      <li v-for="(menu, index) in ASIDE_MENU" :key="menu.id">
        <router-link
          v-if="
            !menu.openModal &&
            ((route.path.split('/')[1] === 'community' && index !== 6) || route.path.split('/')[1] === 'alumni') &&
            menu.id !== 7 &&
            (menu.id !== 9 || (menu.id == 9 && dataCommunity.community_site_available))
          "
          :to="menu.to(route, route?.params?.id)"
          :class="[
            'px-3 w-100 d-flex fs-7 align-items-center w-100  text-decoration-none rounded text-black',
            menu.otherRouterActive(route, route?.params)?.includes(route.path) ? 'other-active' : '',
          ]"
          :target="menu?.targetLink"
        >
          <img :src="menu.icon" class="icon me-2 d-flex justify-content-center align-items-center" />
          <img :src="menu.activeIcon" class="active-icon invisible position-absolute d-flex justify-content-center align-items-center" />

          <span>{{ menu.title }}</span>
        </router-link>
        <a class="cursor-pointer px-3 w-100 d-flex fs-7 align-items-center w-100 text-black text-decoration-none rounded" v-else-if="menu.id === 7" @click="linkSuport">
          <img :src="menu.icon" class="icon me-2 d-flex justify-content-center align-items-center" />
          <img :src="menu.activeIcon" class="active-icon invisible position-absolute d-flex justify-content-center align-items-center" />

          <span>{{ menu.title }}</span></a
        >
        <a
          href="#"
          @click="showInviteModal"
          @click.prevent=""
          v-if="menu.openModal && dataCommunity.can_use_invited_code"
          class="px-3 w-100 d-flex fs-7 align-items-center w-100 text-black text-decoration-none rounded"
        >
          <img :src="menu.icon" class="icon me-2 d-flex justify-content-center align-items-center" />
          <img :src="menu.activeIcon" class="active-icon invisible position-absolute d-flex justify-content-center align-items-center" />

          <span>{{ menu.title }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { ASIDE_MENU } from '@/constants/index'
import { useRoute } from 'vue-router'
import { URL_SUPPORT } from '@/constants/common'
import { useStore } from 'vuex'
import { computed } from 'vue'
import { COMMUNITY_STORE } from '@/store/constants'
import { ref } from 'vue'
import { Modal } from 'bootstrap'

const store = useStore()
const dataCommunity = computed(() => store.getters[COMMUNITY_STORE.GETTERS.COMMUNITY_INFO])

const route = useRoute()
const linkSuport = () => {
  window.open(URL_SUPPORT)
}

const inviteModal = ref()
const inviteModalError = ref()

const showInviteModal = async () => {
  inviteModal.value = new Modal('#inviteModal')
  inviteModalError.value = new Modal('#inviteModalError')
  // コミュニティの情報を更新する
  await store.dispatch(COMMUNITY_STORE.ACTIONS.SET_COMMUNITY_INFO, route.params?.id)
  if (dataCommunity.value.can_use_invited_code) {
    inviteModal.value.show()
  } else {
    inviteModalError.value.show()
  }
}
</script>

<style lang="scss" scoped>
div {
  border-bottom: 1px solid $base-gray-600;
  @include media_tablet() {
    border: none;
  }
  ul {
    li {
      a {
        height: 44px;

        &.router-link-active {
          color: $base-green-200 !important;
          background: #fff;

          .active-icon {
            color: $base-green-200 !important;
            visibility: visible !important;
          }

          .icon {
            visibility: hidden;
          }
        }
        &:hover {
          background: #fff;
          color: $base-green-200 !important;
          .active-icon {
            color: $base-green-200 !important;
            visibility: visible !important;
          }
          .icon {
            visibility: hidden;
          }
        }
      }
    }
  }
}
.other-active {
  color: $base-green-200 !important;
  background: #fff;

  .active-icon {
    color: $base-green-200 !important;
    visibility: visible !important;
  }

  .icon {
    visibility: hidden;
  }
}
</style>
