import { ConversationsStateTypes } from './conversations.types'
import { CONVERSATIONS_STORE } from '../../constants'
import { MutationTree } from 'vuex'
import _ from 'lodash'
import { state as initState } from '@/store/modules/conversations/index'

export const mutations: MutationTree<ConversationsStateTypes> = {
  [CONVERSATIONS_STORE.MUTATIONS.CLEAR_DATA](state: ConversationsStateTypes, payload: any) {
    Object.assign(state, initState)
  },
  [CONVERSATIONS_STORE.MUTATIONS.SET_CHATROOMS](state: ConversationsStateTypes, payload: any) {
    if (payload.change || payload.chatrooms.length === 0) {
      state.chatrooms = payload.chatrooms
    } else {
      state.chatrooms = [...state.chatrooms, ...payload.chatrooms]
    }
    state.total_pages = payload?.total_pages
    state.direct_chatroom_id = payload.direct_chatroom_id
    state.unread_count = payload?.unread_count
    // 2022/10/11 NamPH7425 SM10 (Ph2-R202201021)
    state.total_records = payload.chatrooms.length
    // 2022/10/11 NamPH7425 SM10 (Ph2-R202201021)
    state.loadingData = false
  },
  [CONVERSATIONS_STORE.MUTATIONS.SET_LOADING](state: ConversationsStateTypes, payload: boolean) {
    state.loadingData = payload
  },
  [CONVERSATIONS_STORE.MUTATIONS.SET_MESSAGES_DATA](state: ConversationsStateTypes, payload: any) {
    state.messages = payload
  },
  [CONVERSATIONS_STORE.MUTATIONS.SET_MESSAGE_DETAIL](state: ConversationsStateTypes, payload: any) {
    state.messageDetail = payload
  },
  [CONVERSATIONS_STORE.MUTATIONS.UPDATE_LIST_MESSAGES](state: ConversationsStateTypes, payload: any) {
    let data = state.messages
    let itemRead = data.find((item: any) => Number(item.id) === Number(payload?.id) && item.message_tag === payload?.tag)
    let itemIndex = data.findIndex((item: any) => Number(item.id) === Number(payload?.id) && item.message_tag === payload?.tag)

    state.messages = [...data.slice(0, itemIndex), { ...itemRead, read: true }, ...data.slice(itemIndex + 1)]
  },
  [CONVERSATIONS_STORE.MUTATIONS.SET_LIST_MANAGEMENT_NO](state: ConversationsStateTypes, payload: any) {
    if (payload?.data.length === 0) {
      state.listDataCheckbox[`${payload.typeData}`] = []
      return
    }

    if (!_.map(state.listDataCheckbox[`${payload.typeData}`], 'management_no').includes(payload?.data?.management_no)) {
      state.listDataCheckbox[`${payload.typeData}`].push(payload?.data)
    } else {
      state.listDataCheckbox[`${payload.typeData}`] = state.listDataCheckbox[`${payload.typeData}`].filter((item) => item.management_no !== payload?.data?.management_no)
    }
  },
  [CONVERSATIONS_STORE.MUTATIONS.CHECK_ALL_MANAGEMENT_NO](state: ConversationsStateTypes, payload: any) {
    let allData = []
    if (payload?.type === 'checkAll') {
      allData = payload.data
      state.listDataCheckbox[`${payload.typeData}`] = allData
    } else {
      state.listDataCheckbox[`${payload.typeData}`] = []
    }
  },
  [CONVERSATIONS_STORE.MUTATIONS.SET_ALL_DATA](state: ConversationsStateTypes, payload: any) {
    state.allRecord[`${payload.typeData}`] = payload.data
  },

  [CONVERSATIONS_STORE.MUTATIONS.SET_DATA_SEARCH_DETAIL](state: ConversationsStateTypes, payload: any) {
    if (payload.typeData === 'clearData') {
      state.listSearchDetail.info = []
      state.listSearchDetail.action = []
      state.listSearchDetail.flag = []
    } else {
      state.listSearchDetail[`${payload.typeData}`] = payload.data
    }
  },
}
