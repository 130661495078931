import axios, { AxiosResponse } from 'axios'

const getListAlumnusApi = async ({ id, data, params }: { id: number | string; data: any; params: any }): Promise<AxiosResponse> =>
  axios.post(`/communities/${id}/almunus`, data, { params })

const deleteAlmuniApi = async ({ id, payload }: { id: number | string; payload: any }): Promise<AxiosResponse> => axios.post(`/communities/${id}/ban_almuni_list`, payload)

const csvDownloadAlmuniApi = async ({ id, data }: { id: number | string; data: any }): Promise<AxiosResponse> =>
  axios.post(`/communities/${id}/almunus/csv`, data, { responseType: 'blob' })

const getAlmunusDetailApi = async ({ id, idAlumni }: { id: string | number; idAlumni: string | number }): Promise<AxiosResponse> =>
  axios.get(`/communities/${id}/almunus/${idAlumni}`)

const updateAlmunusDetailApi = async ({ id, idAlumni, data }: { id: string | string[]; idAlumni: string | string[]; data: detailPayload }): Promise<AxiosResponse> =>
  axios.put(`/communities/${id}/almunus/${idAlumni}`, data)

const getListManagingFlagsApi = async (id: string | string[]): Promise<AxiosResponse> => axios.get(`/communities/${id}/users_managed`)

const updateManagementFlagApi = async ({ id, data }: { id: string | string[]; data: managedPayload }): Promise<AxiosResponse> =>
  axios.post(`/communities/${id}/users_managed`, data)

const getAllAlumnus = async ({ id, data }: { id: string | number; data: any }): Promise<AxiosResponse> => axios.post(`/communities/${id}/almunus/get_all_almunus`, data)

const getAllEmployee = async ({ id, data }: { id: string | number; data: any }): Promise<AxiosResponse> => axios.post(`/communities/${id}/users/get_all_users`, data)

const getAllApplicants = async ({ id, params }: { id: string | number; params: any }): Promise<AxiosResponse> =>
  axios.get(`/communities/${id}/applicants/get_all_almunus`, { params })

const updateAlmunusAdditionalDetailApi = async ({
  id,
  idAlumni,
  data,
}: {
  id: string | string[]
  idAlumni: string | string[]
  data: almunusAdditionalPayload
}): Promise<AxiosResponse> => axios.put(`/communities/${id}/almunus/${idAlumni}/update_info`, data)

const communityAlumnusServices = {
  getListAlumnusApi,
  deleteAlmuniApi,
  csvDownloadAlmuniApi,
  getAlmunusDetailApi,
  getListManagingFlagsApi,
  updateAlmunusDetailApi,
  updateManagementFlagApi,
  getAllAlumnus,
  getAllEmployee,
  getAllApplicants,
  updateAlmunusAdditionalDetailApi,
}

export default communityAlumnusServices

type detailPayload = {
  available: boolean
  management_flag: any[]
}
export interface almunusAdditionalPayload {
  additional_information_1: string
  additional_information_2: string
  additional_information_3: string
  additional_information_4: string
  additional_information_5: string
  corporation_department_name: string
  corporation_edit_history_notes: string
  corporation_email: string
  corporation_employee_id: string
  corporation_end_period: string
  corporation_family_name: string
  corporation_given_name: string
  corporation_organization_name: string //
  corporation_start_period: string
  school_department_name: string
  school_email: string
  school_end_period: string
  school_faclty_name: string
  school_family_name: string
  school_given_name: string
  school_organization_name: string //
  school_start_period: string
  school_student_id: string
}

type managedPayload = {
  management_no: any[]
  flag_id: number | string
  type: 'add' | 'remove'
}
